export class WebhookTask {
    id: string;
    created: string;
    createdBy: string;
    modified: string;
    modifiedBy: string;
    status: string;
    data: TaskStatus;
}

export enum TaskStatus {
    Pending = 'Pending',
    Waiting = 'Waiting',
    InProgress = 'InProgress',
    Erroneous = 'Erroneous',
    Canceled = 'Canceled',
    Completed = 'Completed'
}
import { __ } from '@app/shared/functions/object.functions';
import { TrackStatus } from '../enums/TrackStatus';


export class TrackStatusHelper {
    static TrackStatus: any[] = [
        {
            displayName: 'TrackStatus.Offline',
            normalizedName: TrackStatus.Offline,
            value: TrackStatus.Offline
        },
        {
            displayName: 'TrackStatus.Online',
            normalizedName: TrackStatus.Online,
            value: TrackStatus.Online
        },
        {
            displayName: 'TrackStatus.Submitted',
            normalizedName: TrackStatus.Submitted,
            value: TrackStatus.Submitted
        },
        {
            displayName: 'TrackStatus.Pending',
            normalizedName: TrackStatus.Pending,
            value: TrackStatus.Pending
        },
        {
            displayName: 'TrackStatus.Uploaded',
            normalizedName: TrackStatus.Uploaded,
            value: TrackStatus.Uploaded
        }
    ];

    static GetTrackStatusByNameOrNull(displayName: string): any {
        const result = TrackStatusHelper.TrackStatus.find((key: any) => key.displayName === displayName);

        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }

        return result;
    }

    static GetTrackStatusByValueOrNull(value: string): any {
        const result = TrackStatusHelper.TrackStatus.find((key: any) => key.value === value);

        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }

        return result;
    }
}

import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BaseComponent } from '@app/shared/base/components/base-component';

export enum TrackResolution {
  MP3 = 'Mp3',
  WAV = 'Wav'
}

@Component({
    selector: 'sound-download-button',
    templateUrl: './download-button.component.html',
    styleUrls: ['./download-button.component.scss'],
    standalone: false
})
export class DownloadButtonComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  toolTipDisabled: boolean = true;

  TrackResolution = TrackResolution;

  /// -----------------------------------------------------------------------------------------------------
  // @ VIEW CHILD/CHILDREN VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @ViewChild(MatTooltip) tooltip: MatTooltip;

  /// -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Output() downloadClicked: EventEmitter<string> = new EventEmitter<string>();

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    public authenticationService: AuthenticationService,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  isAllowedToDownloadHiRes(): boolean {
    return this.authenticationService.isAuthenticated() && this.authenticationService?.user?.isAllowedForWavPreview;
  }

  download(resolution: string) {

    switch (resolution) {
      case TrackResolution.MP3:
        if (this.authenticationService.isAuthenticated() === true ) {
          this.downloadClicked.emit(TrackResolution.MP3);
          this.trigger.closeMenu();
        } else {
          this.toolTipDisabled = false;
          this.cdr.detectChanges();
          this.tooltip.show();
        }
        break;
      case TrackResolution.WAV:
        if (this.isAllowedToDownloadHiRes() === true ) {
          this.downloadClicked.emit(TrackResolution.WAV);
          this.trigger.closeMenu();
        }
        break;
    }
  }

}

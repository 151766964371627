import { __ } from '@app/shared/functions/object.functions';
import { MusicalKeyType } from '../enums/MusicalKeyType.enum';


export class MusicalKeyTypesHelper {
    static MusicalKeyTypes: any[] = [
        {
            displayName: 'MusicalKey.None',
            value: MusicalKeyType.Undefined
        },
        {
            displayName: 'MusicalKey.A minor',
            value: MusicalKeyType.AMinor
        },
        {
            displayName: 'MusicalKey.E minor',
            value: MusicalKeyType.EMinor
        },
        {
            displayName: 'MusicalKey.B minor',
            value: MusicalKeyType.BMinor
        },
        {
            displayName: 'MusicalKey.F# minor',
            value: MusicalKeyType.FsMinor
        },
        {
            displayName: 'MusicalKey.C# minor',
            value: MusicalKeyType.CsMinor
        },
        {
            displayName: 'MusicalKey.G# minor',
            value: MusicalKeyType.GsMinor
        },
        {
            displayName: 'MusicalKey.D# minor',
            value: MusicalKeyType.DsMinor
        },
        {
            displayName: 'MusicalKey.Bb minor',
            value: MusicalKeyType.BbMinor
        },
        {
            displayName: 'MusicalKey.F minor',
            value: MusicalKeyType.FMinor
        },
        {
            displayName: 'MusicalKey.C minor',
            value: MusicalKeyType.CMinor
        },
        {
            displayName: 'MusicalKey.G minor',
            value: MusicalKeyType.GMinor
        },
        {
            displayName: 'MusicalKey.D minor',
            value: MusicalKeyType.DMinor
        },
        {
            displayName: 'MusicalKey.C major',
            value: MusicalKeyType.CMajor
        },
        {
            displayName: 'MusicalKey.G minor',
            value: MusicalKeyType.GMajor
        },
        {
            displayName: 'MusicalKey.D major',
            value: MusicalKeyType.DMajor
        },
        {
            displayName: 'MusicalKey.A major',
            value: MusicalKeyType.AMajor
        },
        {
            displayName: 'MusicalKey.E major',
            value: MusicalKeyType.EMajor
        },
        {
            displayName: 'MusicalKey.B major',
            value: MusicalKeyType.BMajor
        },
        {
            displayName: 'MusicalKey.F# major',
            value: MusicalKeyType.FsMajor
        },
        {
            displayName: 'MusicalKey.Db major',
            value: MusicalKeyType.DbMajor
        },
        {
            displayName: 'MusicalKey.Ab major',
            value: MusicalKeyType.AbMajor
        },
        {
            displayName: 'MusicalKey.Eb major',
            value: MusicalKeyType.EbMajor
        },
        {
            displayName: 'MusicalKey.Bb major',
            value: MusicalKeyType.BbMajor
        },
        {
            displayName: 'MusicalKey.F major',
            value: MusicalKeyType.FMajor
        }
    ];

    static GetMusicalKeyTypeByNameOrNull(displayName: string): any {
        const result = MusicalKeyTypesHelper.MusicalKeyTypes.find((key: any) => key.displayName === displayName);

        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }

        return result;
    }

    static GetMusicalKeyTypeByValueOrNull(value: string): any {
        const result = MusicalKeyTypesHelper.MusicalKeyTypes.find((key: any) => key.value === value);

        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }

        return result;
    }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BasketSideBarComponent } from '@app/basket/side-bar/basket-side-bar.component';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import {
    LicenseSelectorDialogComponent,
    LicenseSelectorDialogData
} from '@app/licenses/shared/license-selector-dialog/license-selector-dialog.component';
import { ProjectRouteParameter } from '@app/projects/shared/project.route.enum';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { DownloadButtonComponent } from '@app/shared/components/download-button/download-button.component';
import { JadSelectionModel } from '@app/shared/functions/jad-selection-model';
import { __ } from '@app/shared/functions/object.functions';
import { Image } from '@app/shared/models/classes/Image';
import { Project } from '@app/shared/models/classes/Project';
import { Track, TrackVariation, TrackVariationFile, TrackVariationFileType } from '@app/shared/models/classes/Track';
import { BadgeType } from '@app/shared/models/enums/BadgeType.enum';
import { FavoritesType } from '@app/shared/models/enums/FavoritesType.enum';
import { TrackVariationStatus } from '@app/shared/models/enums/TrackVariationStatus.enum';
import { FilesService } from '@app/shared/services/files.service';
import { DownloadsHistoryService } from '@app/shared/services/local/downloads-history.service';
import { FavoritesService } from '@app/shared/services/local/favorites.service';
import { MusicPlayerService } from '@app/shared/services/local/music-player.service';
import { MusicPlaylistService } from '@app/shared/services/local/music-playlist.service';
import { SelectionService } from '@app/shared/services/local/selection.service';
import { ProjectsService } from '@app/shared/services/projects.service';
import { RedirectService } from '@app/shared/services/redirect.service';
import { TracksService } from '@app/shared/services/tracks.service';
import { TrackVariationsService } from '@app/shared/services/trackvariations.service';
import { SimilaritySearchRoute } from '@app/similarity-search/shared/similarity-search.route.enum';
import { TranslateService } from '@ngx-translate/core';
import saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { catchError, concatMap, finalize, of, switchMap } from 'rxjs';

import {
    AddToProjectDialogComponent,
    AddToProjectDialogData
} from '../shared/add-to-project-dialog/add-to-project-dialog.component';
import { S } from '@angular/cdk/keycodes';
import { I18nService } from '@app/core/i18n.service';

@Component({
    selector: 'sound-tracks-variation',
    templateUrl: './tracks-variation.component.html',
    styleUrls: ['./tracks-variation.component.scss'],
    standalone: false
})
export class TracksVariationComponent extends BaseComponent implements OnInit {

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    BadgeType = BadgeType;

    item: TrackVariation;

    isCuratorRoute: boolean = false;

    isCreatorRoute: boolean = false;

    isMyProjectsRoute: boolean = false;

    isSelected: UntypedFormControl = new UntypedFormControl(false);

    isActive: boolean;

    isPlaying: { id: string, isPlaying: boolean } = { id: undefined, isPlaying: false };

    showAddToProjectTooltip: boolean = false;

    projectId: string;

    isDeleteLoading: boolean = false;

    isPublishLoading: boolean = false;

    isSubmitLoading: boolean = false;

    originalVariation: TrackVariation;

    image: string;

    TrackVariationStatus = TrackVariationStatus;

    IsNullOrUndefinedOrFalse = __.IsNullOrUndefinedOrFalse;

    IsNullOrUndefinedOrEmpty = __.IsNullOrUndefinedOrEmpty;

    get waveformId(): string {
        return this.item?.id + this.trackListId
    }

    // -----------------------------------------------------------------------------------------------------
    // @ VIEW CHILD/CHILDREN VARIABLES
    // -----------------------------------------------------------------------------------------------------
    @ViewChild(DownloadButtonComponent) element: DownloadButtonComponent;

    /// -----------------------------------------------------------------------------------------------------
    // @ OUTPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Output() editClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() deleteClicked: EventEmitter<void> = new EventEmitter<void>();

    @Output() removedFromPlaylist: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() deletedTrack: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() publishedTrack: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() submittedTrack: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() addAllTracksToPlaylist: EventEmitter<{ id: string, index: number }> = new EventEmitter<{ id: string, index: number }>();

    @Output() toggledFavorite: EventEmitter<boolean> = new EventEmitter<boolean>();

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Input() isMainItem: boolean = false;

    @Input() canRate: boolean = false;

    @Input() selectable: boolean = false;

    @Input() isDeletable: boolean = false;

    @Input() variationsCount: number = 0;

    @Input() showVariations: UntypedFormControl;

    @Input() shouldLoadWaveform: boolean = true;

    @Input() shouldResetPlaylist: boolean = false;

    @Input() shouldAddAllTracksToPlaylist: boolean = false;

    @Input() viewWidth: number;

    private _track: Track;

    @Input()
    get track(): Track {
        return this._track;
    }
    set track(value: Track) {
        this._track = value;

        if (!__.IsNullOrUndefined(value)) {
            this.track.variations.map(q => q.lengthTime = TrackVariation.getLength(q.length));
            this.setItem();
        }
    }

    private _trackListId: string;
    @Input()
    public set trackListId(trackListId: string) {
        this._trackListId = trackListId;
    }
    public get trackListId(): string {
        return this._trackListId;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private tracksService: TracksService,
        private dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private redirectService: RedirectService,
        private router: Router,
        private route: ActivatedRoute,
        private i18nService: I18nService,
        private selectionService: SelectionService<Track>,
        private favoritesService: FavoritesService,
        private downloadsHistoryService: DownloadsHistoryService,
        private filesService: FilesService,
        private musicPlayerService: MusicPlayerService,
        private musicPlaylistService: MusicPlaylistService,
        private projectsService: ProjectsService,
        private toastr: ToastrService,
        private translateService: TranslateService,
        private trackVariationsService: TrackVariationsService
    ) {
        super();

        this.display = 'table-row';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ LIFE CYCLE HOOKS
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {
        this.setItem();

        // Limit columns for path project, creator and curator
        if (this.router.url.includes(AppRoute.MyProjects)) {
            this.isMyProjectsRoute = true;
            this.projectId = this.route.snapshot.params[ProjectRouteParameter.ProjectId];
        }
        if (this.router.url.includes(AppRoute.Creator)) { this.isCreatorRoute = true; }
        if (this.router.url.includes(AppRoute.Curator)) { this.isCuratorRoute = true; }

        // Check if track is selected
        if (!this.IsNullOrUndefinedOrEmpty(this.track) && this.selectionService.isSelected(this.trackListId, this.track)) {
            this.isSelected.setValue(this.isTrackVariationSelected(), { emitEvent: false });
        }

        // Subscribe to isSelected form control and push into selectedRows array if value is true or splice if value is false
        super.addSubscription(
            this.isSelected.valueChanges.subscribe({
                next: (value: boolean) => {
                    this.toggleSelectedTrackVariation(value);
                }
            })
        );

        // Subscribe to selectedRows and set value of isSelected when changed value has item in it
        super.addSubscription(
            this.selectionService
                .selectionChanges$
                .subscribe({
                    next: (selection: JadSelectionModel<Track>) => {
                        if (selection.id !== this.trackListId) {
                            return;
                        }
                        if (this.isTrackVariationSelected() === true) {
                            this.isSelected.setValue(true, { emitEvent: false });
                        }
                        else if (this.isTrackVariationSelected() === false) {
                            this.isSelected.setValue(false, { emitEvent: false });
                        }
                    }
                })
        );

        // Track if active in playlist
        super.addSubscription(
            this.musicPlaylistService
                .activeTrack$
                .subscribe({
                    next: (track: { track: Track, index: number }) => {
                        if (!this.IsNullOrUndefinedOrEmpty(track)) {
                            if (track.track.variations[track.index].id === this.item.id) {
                                this.isActive = true;
                            }
                            else {
                                this.isActive = false;
                            }
                        }
                    }
                })
        );

        // only do it for tracks-details here to reduce number of subscriptions
        if (this.trackListId === 'tracks-details') {
            super.addSubscription(
                this.musicPlayerService.trackProjectChanges$.subscribe({
                    next: (changes: { trackId: string, projectIds: string[] }) => {
                        if (changes.trackId === this.track.id) {
                            this.track.projectIds = changes.projectIds;
                        }
                    }
                })
            );
        }

        // Check if playing
        this.isPlaying = this.musicPlayerService.isPlaying;

        // Track if playing
        super.addSubscription(
            this.musicPlayerService
                .play$
                .subscribe({
                    next: (isPlaying: { id: string, isPlaying: boolean }) => {
                        this.isPlaying = isPlaying;
                    }
                })
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    /**
     * The tooltip will be disabled if not truncated
     */
    isDisabledTooltip(e: any) {
        return e.scrollWidth <= e.clientWidth;
    }

    isTrackVariationSelected(): boolean {
        const selectedTrack: Track = this.selectedTrack();

        if (!__.IsNullOrUndefined(selectedTrack)) {
            return selectedTrack.variations.findIndex(q => q.id === this.item.id) !== -1;
        }

        return false;
    }

    selectedTrack(): Track {
        const selection: JadSelectionModel<Track> = this.selectionService.selections.find(q => q.id === this.trackListId);

        if (__.IsNullOrUndefined(selection)) {
            return null;
        }

        return __.Extend(selection.selected.find(q => q.id === this.track.id));
    }

    getSource(): string {
        // TODO: GET THE PROPER VARIATION 
        const files: TrackVariationFile[] = this.item?.files || [];
        const fileId: string = files.find(q => q.type === TrackVariationFileType.Mp3)?.url || '';

        return fileId;
    }

    toggleSelectedTrackVariation(selected: boolean): void {
        let selectedTrack: Track = this.selectedTrack();
        let selectedTrackVariationIndex: number = -1;

        if (!__.IsNullOrUndefined(selectedTrack)) {
            selectedTrackVariationIndex = selectedTrack.variations.findIndex(q => q.id === this.item.id);
        } else {
            selectedTrack = __.Extend(this.track);
            selectedTrack.variations = [];
        }

        if (selected === true) {
            // Add track variation to selection with current variations
            if (selectedTrackVariationIndex === -1) {
                selectedTrack.variations.push(this.item);
                this.selectionService.deselect(this.trackListId, this.track);
                if (this.isMainItem === true) {
                    this.selectionService.select(this.trackListId, this.track);
                } else {
                    this.selectionService.select(this.trackListId, selectedTrack);
                }
            }
        }

        if (selected === false) {
            // Remove track variation from selection while keeping current variations
            if (selectedTrackVariationIndex !== -1) {
                selectedTrack.variations.splice(selectedTrackVariationIndex, 1);
                this.selectionService.deselect(this.trackListId, this.track);
                if (this.isMainItem !== true) {
                    this.selectionService.select(this.trackListId, selectedTrack);
                }
            }
        }
    }

    toggleFavorites(): void {
        this.item.isFavorite = !this.item.isFavorite;

        this.favoritesService
            .toggleFavoriteByType(this.track, FavoritesType.Track)
            .subscribe({
                next: (value: any) => {
                    if (this.router.url.includes(AppRoute.Favourites)) {
                        this.toggledFavorite.emit(true);
                    }
                }
            });
    }

    isFavorite(): boolean {
        if (this.IsNullOrUndefined(this.item)) return;
        return this.favoritesService.isFavoriteByType(this.track.id, FavoritesType.Track);
    }

    toggleVariations(): void {
        if (this.isMainItem) {
            this.showVariations.setValue(!this.showVariations.value);
        }
    }

    openAddToProjectDialog(): void {
        if (this.authenticationService.isAuthenticated() === true) {
            super.addSubscription(
                this.dialog.open(AddToProjectDialogComponent, {
                    data: Object.assign(new AddToProjectDialogData(), {
                        trackId: this.track.id,
                        projectIds: this.track?.projectIds
                    })
                }).afterClosed()
                    .subscribe({
                        next: (result: any) => {
                            if (!__.IsNullOrUndefinedOrEmpty(result)) {
                                this.track.projectIds.push(...result);
                                this.musicPlayerService.updateProjectsForTrack(this.track.id, this.track.projectIds);
                            }
                        }
                    })
            );
        } else {
            this.router.navigate([this.i18nService.getAbsoluteUrlWithCurrentLanguage(`/${AppRoute.Login}`)]);
        }
    }

    openRemoveFromProjectDialog(): void {
        super.addSubscription(
            this.dialog.open(ConfirmDialogComponent, {
                data: Object.assign(new ConfirmDialogData(), {
                    icon: 'my_projects',
                    displayText: 'Tracks.Do you want to remove this track from your project?',
                }),
                maxWidth: '58.571rem'
            }).afterClosed()
                .pipe(
                    switchMap((isConfirmed: boolean) => {
                        if (isConfirmed === true) {
                            return this.projectsService.removeTracksFromProjectById(this.projectId, [this.track.id]);
                        }
                        return of(null);
                    })
                )
                .subscribe({
                    next: (project: Project) => {
                        this.track.projectIds.splice(this.track.projectIds.findIndex(q => q === this.projectId), 1);
                        this.musicPlayerService.updateProjectsForTrack(this.track.id, this.track.projectIds);

                        this.toastr.success(this.translateService.instant('Tracks.The track has been successfully removed from this project'));
                        this.removedFromPlaylist.emit(true);
                    }
                })
        );
    }

    openShareDialog(): void {
        // TODO v2
    }

    openSimilaritySearchDialog(): void {
        this.musicPlayerService.pause(this.waveformId);
        this.musicPlayerService.pause(this.waveformId, true);

        this.router.navigateByUrl(this.i18nService.getAbsoluteUrlWithCurrentLanguage(`${AppRoute.Home}`), { skipLocationChange: true }).then(() => {
            this.router.navigate([this.i18nService.getAbsoluteUrlWithCurrentLanguage(`${AppRoute.SimilaritySearch}/${SimilaritySearchRoute.Track}`), this.track?.id]);
        });
    }

    downloadTrackVariationByResolution(resolution: string): void {
        const files: TrackVariationFile[] = this.item?.files || [];
        const fileUrl: string = files.find(q => q.type === resolution)?.url || '';
        const fileId: string = files.find(q => q.type === resolution)?.id;

        super.addSubscription(
            this.filesService
                .downloadFile(fileId)
                .subscribe({
                    next: (data: { blob: Blob, name: string }) => {
                        saveAs(data.blob, data.name);
                        this.downloadsHistoryService.addDownloadedTrack(this.track);
                    },
                    error: (error: any) => {
                        console.error(error);
                    }
                })
        );
    }

    openDeleteDialog(): void {
        if (this.isDeleteLoading === false) {
            this.isDeleteLoading = true;
            super.addSubscription(
                this.dialog.open(ConfirmDialogComponent, {
                    data: Object.assign(new ConfirmDialogData(), {
                        icon: 'trash',
                        displayText: 'Tracks.Do you want to delete this'
                    }),
                    maxWidth: '58.571rem'
                }).afterClosed()
                    .pipe(
                        switchMap((isConfirmed: boolean) => {
                            if (isConfirmed === true && this.item.isOriginal === true) {
                                this.toastr.warning(this.translateService.instant('Tracks.The deletion of the track might take a couple of minutes. Please do not leave the view'));
                                return this.tracksService.deleteById(this.track.id);
                            }
                            if (isConfirmed === true) {
                                this.toastr.warning(this.translateService.instant('Tracks.The deletion of the track variation might take a couple of minutes. Please do not leave the view'));
                                return this.trackVariationsService.deleteById(this.item.id);
                            }
                            return of(null);
                        }),
                        catchError((error: any) => {
                            this.isDeleteLoading = false;
                            return of(null);
                        }),
                        finalize(() => {
                            this.isDeleteLoading = false;
                        })
                    )
                    .subscribe({
                        next: (success: boolean) => {
                            if (success === true) {
                                this.toastr.success(this.translateService.instant('Tracks.The track has been successfully removed'));
                                this.deletedTrack.emit(true);
                            }
                        },
                        error: (error: any) => {
                            this.toastr.error(this.translateService.instant('Tracks.Something went wrong'));
                            throw error;
                        }
                    })
            );
        }
    }

    editTrack(): void {
        this.editClicked.emit(true);
    }

    publishTrack(): void {
        if (this.isPublishLoading === false) {
            this.isPublishLoading = true;
            super.addSubscription(
                this.dialog.open(ConfirmDialogComponent, {
                    data: Object.assign(new ConfirmDialogData(), {
                        icon: 'publish',
                        displayText: 'Tracks.Do you want to publish this'
                    }),
                    maxWidth: '58.571rem'
                }).afterClosed()
                    .pipe(
                        switchMap((isConfirmed: boolean) => {
                            if (
                                isConfirmed === true &&
                                (this.track.variations.findIndex(q => q.isOriginal === true && q.status === TrackVariationStatus.Online) !== -1 ||
                                    this.item.isOriginal === true)
                            ) {
                                return this.trackVariationsService.updateTrackVariationStatusByIds([this.item.id], TrackVariationStatus.Online, true);
                            }
                            return of(null);
                        }),
                        catchError((error: any) => {
                            this.isPublishLoading = false;
                            return of(null);
                        }),
                        finalize(() => {
                            this.isPublishLoading = false;
                        })
                    )
                    .subscribe({
                        next: (success: string) => {
                            if (!this.IsNullOrUndefined(success)) {
                                this.toastr.success(this.translateService.instant('Tracks.The track has been successfully published'));
                                this.publishedTrack.emit(true);
                            }
                        },
                        error: (error: any) => {
                            this.toastr.error(this.translateService.instant('Tracks.Something went wrong'));
                            throw error;
                        }
                    })
            );
        }
    }

    submitTrack(): void {
        if (this.isSubmitLoading === false) {
            this.isSubmitLoading = true;

            super.addSubscription(
                this.dialog.open(ConfirmDialogComponent, {
                    data: Object.assign(new ConfirmDialogData(), {
                        icon: 'submit',
                        displayText: 'Tracks.Do you want to submit this'
                    }),
                    maxWidth: '58.571rem'
                }).afterClosed()
                    .pipe(
                        switchMap((isConfirmed: boolean) => {
                            if (isConfirmed === true) {
                                return this.trackVariationsService.updateTrackVariationStatusByIds([this.item.id], TrackVariationStatus.Submitted);
                            }
                            return of(null);
                        }),
                        catchError((error: any) => {
                            this.isSubmitLoading = false;
                            return of(null);
                        }),
                        finalize(() => {
                            this.isSubmitLoading = false;
                        })
                    )
                    .subscribe({
                        next: (success: string) => {
                            if (!this.IsNullOrUndefined(success)) {
                                this.toastr.success(this.translateService.instant('Tracks.The track has been successfully submitted'));
                                this.submittedTrack.emit(true);
                            }
                        },
                        error: (error: any) => {
                            this.toastr.error(this.translateService.instant('Tracks.Something went wrong'));
                            throw error;
                        }
                    })
            );
        }
    }

    openLicenseSelectorDialog(): void {
        super.addSubscription(
            this.dialog.open(LicenseSelectorDialogComponent, {
                data: Object.assign(new LicenseSelectorDialogData(), {
                    track: this.track
                }),
                maxWidth: '58.571rem'
            }).afterClosed()
                .pipe(
                    concatMap((response: any) => {
                        if (this.IsNullOrUndefinedOrEmpty(response)) {
                            return of(null);
                        }

                        return this.dialog.open(BasketSideBarComponent, {
                            position: {
                                top: '0px',
                                right: '0px',
                                bottom: '0px'
                            },
                            width: '28.571rem',
                            height: '100vh'
                        }).afterClosed();
                    })
                )
                .subscribe({
                    next: (shouldCheckout: any) => {
                        if (shouldCheckout === true) {
                            let route = `${AppRoute.Login}`;

                            if (this.authenticationService.isAuthenticated()) {
                                route = `${AppRoute.Checkout}`;
                            } else {
                                // redirect the user after login to route
                                this.redirectService.setRedirectUri(`${AppRoute.Checkout}`);
                            }

                            this.router.navigateByUrl(this.i18nService.getAbsoluteUrlWithCurrentLanguage(route));
                        }
                    }
                })
        );
    }

    togglePlay(): void {
        if (this.musicPlayerService.isPlaying.isPlaying === false) {
            this.musicPlayerService.play(this.item?.id + this.trackListId);
        } else if (this.isActive === true && this.isPlaying.isPlaying === false) {
            this.musicPlayerService.play(this.item?.id + this.trackListId);
            return;
        } else if (this.isActive === true) {
            this.musicPlayerService.pause(this.item?.id + this.trackListId);
            return;
        }

        if (this.shouldAddAllTracksToPlaylist) {
            this.addAllTracksToPlaylist.emit({ id: this.track.id, index: this.getVariationIndex() });
        } else {
            // Add to playlist
            if (this.shouldResetPlaylist) {
                this.musicPlaylistService.setPlaylist([this.track], { id: this.track.id, index: this.getVariationIndex() });
            } else {
                this.musicPlaylistService.addTracksToPlaylist([this.track], true, { id: this.track.id, index: this.getVariationIndex() });
            }
        }

        this.musicPlayerService.toggleMusicPlayer(this.item.id, true);
    }

    getPeaksURL(): string {
        const files: TrackVariationFile[] = this.item?.files || [];
        const fileId: string = files.find(q => q.type === TrackVariationFileType.AudioForm)?.url || '';

        return fileId;
    }


    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE METHODS
    // -----------------------------------------------------------------------------------------------------

    setItem(): void {
        if (!this.IsNullOrUndefinedOrEmpty(this.track)) {
            if (this.isMainItem && !__.IsNullOrUndefined(this.track['variations'])) {
                this.item = (this.track as Track).variations[0];
            } else if (!__.IsNullOrUndefined(this.track['variations'])) {
                this.item = (this.track as Track).variations[this.variationsCount];
            } else {
                // TODO: Handle case for this.track instanceof TrackVariation
            }

            if (!this.IsNullOrUndefinedOrEmpty(this.track.artist)) {
                this.image = this.getItemImage(this.track.artist.images);
            }

            this.originalVariation = this.track.variations?.find(q => q.isOriginal === true);
            // Check if active track
            this.isActive = this.musicPlaylistService.isActive(this.track?.id, this.getVariationIndex());
        }
    }

    private getItemImage(images: Image[]): string {
        if (this.IsNullOrUndefinedOrEmpty(images) || this.IsNullOrUndefinedOrEmpty(images[0].variations)) {
            return null;
        }

        if (images[0].variations.some(q => q.type === 'Thumbnail') === false) {
            return images[0].variations[0].src;
        }

        return images[0].variations.find(q => q.type === 'Thumbnail').src;
    }

    private getVariationIndex(): number {
        return this.track.variations.findIndex(variation => variation.id === this.item.id);
    }
}
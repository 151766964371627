<!-- FORM -->
<div class="my-projects__form">

  <!-- FORM GROUP -->
  <div class="my-projects__form-group">

    <!-- MUSIC TITLE -->
    <jad-form-fields-text [control]="form.get('displayName')" [placeholder]="'MyProjects.Project Name' | translate"
      [displayName]="'MyProjects.Project Name' | translate" [required]="true"></jad-form-fields-text>

    <!-- DESCRIPTION -->
    <jad-form-fields-textarea [control]="form.get('description')"
      [placeholder]="'MyProjects.Description (optional)' | translate"
      [displayName]="'MyProjects.Description' | translate" [required]="false"></jad-form-fields-textarea>

    <!-- PUBLICATION STATUS -->
    <jad-form-fields-select name="playlistPublicationStatus" [control]="form.get('playlistPublicationStatus')"
      [required]="true" [width]="'100%'" displayName="{{ 'MyProjects.Publication status' | translate }}"
      placeholder="{{ 'Register.Select' | translate }}" [items]="PlaylistPublicationStatusHelper"
      [disabled]="!IsNullOrUndefined(currentUser?.organisationId) && !currentUser?.isAllowedForPublicProjects">
    </jad-form-fields-select>
  </div>

  <!-- ACTIONS -->
  <div class="my-projects__actions">
    <!-- SUBMIT -->
    <sound-buttons-submit class="my-projects__actions__submit" [colorClass]="'purple'" [isLoading]="isLoading"
      [isSuccessful]="isSuccessful" [isDisabled]="!form.valid || isLoading === true" (onClick)="submit()"
      [displayText]="submitButtonText">
    </sound-buttons-submit>
  </div>
</div>
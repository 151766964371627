import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BaseFormComponent } from '@app/shared/base/components/base-form.component';
import { ApplicationUser } from '@app/shared/models/classes/ApplicationUser';
import { PlaylistPublicationStatus } from '@app/shared/models/classes/Playlist';
import { Project, ProjectCreateDto, ProjectUpdateDto } from '@app/shared/models/classes/Project';
import { LanguageCode } from '@app/shared/models/enums/LanguageCode.enum';
import { PlaylistPublicationStatusHelper } from '@app/shared/models/helpers/PlaylistPublicationStatus.helper';
import { ProjectRoleHelper } from '@app/shared/models/helpers/ProjectRole.helper';
import { ProjectsService } from '@app/shared/services/projects.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';

@Component({
    selector: 'sound-projects-form',
    templateUrl: './projects-form.component.html',
    styleUrls: ['./projects-form.component.scss'],
    standalone: false
})
export class ProjectsFormComponent extends BaseFormComponent<Project> implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // ------------------------------------------------------------------------------------------------------

  submitButtonText: string;

  isSuccessful: boolean = false;

  PlaylistPublicationStatusHelper = PlaylistPublicationStatusHelper.PublicationStatuses;

  currentUser: ApplicationUser;

  /// -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // ------------------------------------------------------------------------------------------------------

  private _projectId: string;
  @Input()
  public set projectId(projectId: string) {
    this._projectId = projectId;

    if (!this.IsNullOrUndefinedOrEmpty(projectId)) {

      this.isLoading = true;

      super.addSubscription(
        this.projectsService
          .getById(projectId, '', true, true)
          // .getById(projectId, '', true, true)
          .pipe(
            finalize(() => this.isLoading = false)
          )
          .subscribe({
            next: (project: Project) => {
              this.item = project;
              this.submitButtonText = this.translateService.instant('MyProjects.Save');
              this.patchFormValues();
            }
          })
      );
    }
  }
  public get projectId(): string {
    return this._projectId;
  }



  /// -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  // tslint:disable-next-line:member-ordering
  @Output() submitted: EventEmitter<boolean> = new EventEmitter<boolean>();

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private projectsService: ProjectsService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService
  ) {
    super();

  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.currentUser = this.authenticationService.user;
    this.setForm();
    this.submitButtonText = this.translateService.instant('MyProjects.Create');

    super.addSubscription(
      this.form
        .valueChanges
        .subscribe()
    );
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  setForm(): void {
    this.form = this.fb.group({
      displayName: ['', Validators.required],
      description: '',
      playlistPublicationStatus: [PlaylistPublicationStatus.Private, Validators.required]
    });
  }

  patchFormValues(): void {
    this.form.patchValue({
      displayName: this.getDisplayNameByLanguageCode(LanguageCode.deDE),
      description: this.getDescriptionByLanguageCode(LanguageCode.deDE),
      playlistPublicationStatus: this.item?.playlistPublicationStatus
    });
  }

  create(): void {
    this.isLoading = true;

    const values = this.form.value;

    const newProject = Object.assign(
      new ProjectCreateDto(),
      {
        translations: [
          {
            displayName: values.displayName,
            description: values.description,
            languageCode: LanguageCode.deDE
          },
          {
            displayName: values.displayName,
            description: values.description,
            languageCode: LanguageCode.enUS
          }
        ],
        playlistPublicationStatus: values.playlistPublicationStatus
      }
    );

    super.addSubscription(
      this.projectsService
        .create(newProject)
        .pipe(
          finalize(() => this.isLoading = false)
        )
        .subscribe({
          next: (project: Project) => {
            this.item = project;
            this.patchFormValues();
            this.submitButtonText = this.translateService.instant('MyProjects.Save');
            this.submitted.emit(true);

            this.toastr.success(this.translateService.instant('MyProjects.Your project was successfully created'));
            this.isSuccessful = true;
          }
        })
    );
  }

  update(): void {
    this.isLoading = true;

    const values = this.form.value;

    const updatedProject = Object.assign(
      new ProjectUpdateDto(),
      {
        translations: [
          {
            displayName: values.displayName,
            description: values.description,
            languageCode: LanguageCode.deDE
          },
          {
            displayName: values.displayName,
            description: values.description,
            languageCode: LanguageCode.enUS
          }
        ],
        playlistPublicationStatus: values.playlistPublicationStatus
      }
    );

    super.addSubscription(
      this.projectsService
        .update(this.item.id, updatedProject)
        .pipe(
          finalize(() => this.isLoading = false)
        )
        .subscribe({
          next: (project: Project) => {
            this.item = project;
            this.patchFormValues();
            this.submitted.emit(true);

            this.toastr.success(this.translateService.instant('MyProjects.Your project was successfully updated'));
            this.isSuccessful = true;
          }
        })
    );
  }

  submit(): void {
    if (this.IsNullOrUndefined(this.item)) {
      this.create();
      return;
    }

    this.update();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // ------------------------------------------------------------------------------------------------------

  private getDisplayNameByLanguageCode(languageCode: string): string {
    let displayName: string = '';

    displayName = this.item?.playlists_i18n?.find(q => q.languageCode === languageCode)?.displayName ||
      this.item?.translations?.find(q => q.languageCode === languageCode)?.displayName ||
      '';

    if (this.IsNullOrUndefined(displayName)) {
      return '';
    }

    return displayName;
  }

  private getDescriptionByLanguageCode(languageCode: string): string {
    let description: string = '';

    description = this.item?.playlists_i18n?.find(q => q.languageCode === languageCode)?.description ||
      this.item?.translations?.find(q => q.languageCode === languageCode)?.description ||
      '';

    if (this.IsNullOrUndefined(description)) {
      return '';
    }

    return description;
  }

}

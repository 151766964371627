export enum SimilaritySearchRoute {
    Track = 'track',
    MP3 = 'mp3',
    Spotify = 'spotify',
    FreeTextSearch = 'free-text-search' 
}

export enum SimilaritySearchRouteParameter {
    TrackId = 'trackId',
    CyaniteId = 'cyaniteId',
    SpotifyId = 'spotifyId',
    FreeTextSearchText = 'freeTextSearchText'
}
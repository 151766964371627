import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import { I18nService } from '@app/core/i18n.service';
import { map, Observable } from 'rxjs';

import { Playlist, PlaylistShareCreateOptions } from '../models/classes/Playlist';
import {
  CommentInTrackInProject,
  CommentInTrackInProjectUpdateOptions,
  Project,
  ProjectCreateDto,
  ProjectUpdateDto,
  PublicationStatusUpdateOptions,
  RatingInTrackInProjectUpdateOptions,
} from '../models/classes/Project';
import { EntityService } from './entity.service';
import { Response } from './local/Response';

@Injectable()
export class ProjectsService extends EntityService<Project, ProjectCreateDto, ProjectUpdateDto> {

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(protected httpService: HttpService, protected i18nService: I18nService) {
    super('playlists', 'project', httpService, i18nService);
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  addTracksToProjectByIds(projectId: string, trackIds: { trackId: string; orderNumber?: number }[]): Observable<Project> {
    return this.httpService.patch<Response<Project>>(`playlists/${projectId}/tracks`, { tracksToAdd: trackIds })
      .pipe(
        map((response: Response<Project>) => {
          return response.data;
        })
      );
  }

  removeTracksFromProjectById(projectId: string, trackIds: string[]): Observable<Project> {
    return this.httpService.patch<Response<Project>>(`playlists/${projectId}/tracks`, { trackIdsToRemove: trackIds })
      .pipe(
        map((response: Response<Project>) => {
          return response.data;
        })
      );
  }

  shareProject(id: string, playlistShareData: PlaylistShareCreateOptions): Observable<Playlist> {
    return this.httpService.post<Response<Playlist>>(`playlists/${id}/share`, playlistShareData).pipe(
      map((response: Response<Playlist>) => {
        return response.data;
      })
    );
  }

  /**
   * Update the comment on track inside a project
   * @param id The project id
   * @param trackId The track id
   * @param updateOptions The comment data
   * @returns 
   */
  updateTrackComment(id: string, trackId: string, updateOptions: CommentInTrackInProjectUpdateOptions) {
    return this.httpService.patch<Response<CommentInTrackInProject>>(`playlists/${id}/track/${trackId}/comment`, updateOptions).pipe(
      map((response:Response<CommentInTrackInProject>) => {
        return response.data;
      })
    );
  }

  /**
   * Update the rating on track inside a project
   * @param id The project id
   * @param trackId The track id
   * @param updateOptions The comment data
   * @returns 
   */
  updateTrackRating(id: string, trackId: string, updateOptions: RatingInTrackInProjectUpdateOptions) {
    return this.httpService.patch<Response<any>>(`playlists/${id}/track/${trackId}/Rating`, updateOptions).pipe(
      map((response:Response<any>) => {
        return response.data;
      })
    );
  }

    /**
   * Update the project publication status
   * @param id The project id
   * @param updatedOptions The publication status data
   * @returns 
   */
    updatePublicationStatus(id: string, updatedOptions: PublicationStatusUpdateOptions) {
      return this.httpService.patch<Response<any>>(`playlists/${id}/publicationStatus`, updatedOptions).pipe(
        map((response:Response<any>) => {
          return response.data;
        })
      );
    }
}
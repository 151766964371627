import { Component, HostBinding, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';
import { Basket, BasketItemGroup, ItemInBasket } from '@app/shared/models/classes/Basket';
import { Track, TrackVariation } from '@app/shared/models/classes/Track';
import { BadgeType } from '@app/shared/models/enums/BadgeType.enum';
import { BasketService } from '@app/shared/services/basket.service';
import { LicensesService } from '@app/shared/services/licenses.service';

@Component({
    selector: 'sound-basket-side-bar',
    templateUrl: './basket-side-bar.component.html',
    styleUrls: ['./basket-side-bar.component.scss'],
    standalone: false
})
export class BasketSideBarComponent extends BaseComponent implements OnInit {

    @HostBinding('style.display') display: string = 'flex';

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    basketItemGroups: BasketItemGroup[] = [];

    BadgeType = BadgeType;

    showTax: boolean = true;

    total: number = 0;

    subTotal: number = 0;

    percentOff: number = 0;

    discounted: number = 0;

    withTax: number = 0;

    image: string;

    hasAnyCustomLicense: boolean = false;

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private basketService: BasketService,
        public dialogRef: MatDialogRef<BasketSideBarComponent>,
        private authenticationService: AuthenticationService
    ) {
        super();

        if (this.authenticationService.isAuthenticated() && this.authenticationService.user.discount > 0) {
            this.percentOff = this.authenticationService.user.discount;
        }

        this.withTax = this.basketService.getTaxPercent();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ LIFE CYCLE HOOKS
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {
        super.addSubscription(
            this.basketService.getBasket().subscribe({
                next: (basket: Basket) => {
                    this.basketItemGroups = BasketService.GroupByTrack(basket);

                    this.total = this.basketService.calculateTotal();
                    this.subTotal = this.basketService.calculateSubTotal();

                    if (this.percentOff > 0) {
                        this.discounted = this.total * this.percentOff / 100;
                    }

                    this.hasAnyCustomLicense = this.basketItemGroups.some(q => q.items.some(a => LicensesService.isCustomLicense(a.license)));

                    if (!__.IsNullOrUndefined(basket.shoppingBasketItems[0])) {
                        this.showTax = basket.shoppingBasketItems[0].license.price.showTax;
                    }
                }
            })
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    getOriginalVariation(track: Track): TrackVariation {
        return track?.variations?.find(q => q.isOriginal === true);
    }

    shareCart(): void {
        // TODO: V2
        // TODO: Generates a link which can be used to replicate the current shopping basket in another view
        // TODO: Perhaps route with a marker to replicate the json in the url in the basket and having it encoded ofc
        // TODO: URL/basket?item={licenseId=licenseId,trackId=trackId,quantity=quantity}&....
    }

    updateQuantity(currentItem: BasketItemGroup, licenseId: string, newQuantity: number): void {
        if (newQuantity === 0) {
            this.deleteLicense(currentItem, licenseId);
        } else {
            super.addSubscription(
                this.basketService.updateItemQuantity(currentItem.track.id, licenseId, newQuantity).subscribe({
                    next: (value: ItemInBasket) => {
                        currentItem.items.find(q => q.license.id === licenseId).quantity = value.quantity;

                        this.total = this.basketService.calculateTotal();
                        this.subTotal = this.basketService.calculateSubTotal();

                        if (this.percentOff > 0) {
                            this.discounted = this.total * this.percentOff / 100;
                        }
                    }
                })
            );
        }
    }

    deleteLicense(currentItem: BasketItemGroup, licenseId: string): void {
        super.addSubscription(
            this.basketService.removeItem(currentItem.track.id, licenseId).subscribe({
                next: (success: boolean) => {
                    if (success === true) {
                        if (currentItem.items.length > 1) {
                            currentItem.items.splice(currentItem.items.findIndex(q => q.license.id === licenseId), 1);
                        } else {
                            this.basketItemGroups.splice(this.basketItemGroups.findIndex(q => q.track.id === currentItem.track.id), 1);
                        }

                        this.total = this.basketService.calculateTotal();
                        this.subTotal = this.basketService.calculateSubTotal();

                        if (this.percentOff > 0) {
                            this.discounted = this.total * this.percentOff / 100;
                        }
                    }
                }
            })
        );
    }
}

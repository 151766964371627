import { DataSource } from '@angular/cdk/table';
import { BaseDatabase } from '@app/shared/base/components/base-database.component';
import { ReloadBehavior } from '@app/shared/base/models/reload-behaviour';
import { __ } from '@app/shared/functions/object.functions';
import { TrackVariation } from '@app/shared/models/classes/Track';
import { TrackHeader } from '@app/shared/models/helpers/TrackHeaders.helper';
import { merge, Observable } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';


export class LocalStorageDataSource<T> extends DataSource<T> {

    /// -----------------------------------------------------------------------------------------------------
    // @ PRIVATE INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    private _items: T[] = [];

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        public database: BaseDatabase<T>,
        public items: T[],
        public items$: Observable<T[]>,
        public fetchOnSubscribe: boolean = false
    ) {
        super();

        // Set items
        this._items = [...__.Extend(items)];
        if (!__.IsNullOrUndefined(this.database.paginator)) {
            this.database.paginator.length = this._items.length;
    
            // Set inital page size
            this.database.paginator.pageSize = 10;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    /**
     * Connects to a stream of observables, such as the paginator, the sorter,
     * the reload of the database or the filter. An update is performed
     * base on the provided reload behavior.
     *
     */
    connect(): Observable<T[]> {
        const observables: Observable<any>[] = [
            this.database.reload$
        ];
        if (!__.IsNullOrUndefined(this.database.paginator)) {
            observables.push(this.database.paginator.page);
        }
        if (!__.IsNullOrUndefined(this.database.sort)) {
            observables.push(this.database.sort.sortChange);
        }
        if (!__.IsNullOrUndefined(this.database.filterValuesChanged$)) {
            observables.push(this.database.filterValuesChanged$);
        }
        if (!__.IsNullOrUndefined(this.items$)) {
            observables.push(
                this.items$
                    .pipe(
                        map((items: T[]) => {
                            this._items = items;
                            this.database.paginator.length = this._items.length;
                        }
                        )
                    )
            );
        }

        if (this.fetchOnSubscribe === true) {
            setTimeout(() => {
                this.reloadData();
            }, 100);
        }

        return merge(...observables)
            .pipe(
                switchMap((value: any) => {
                    const items: T[] = [];

                    const reloadBehavior = Object.assign(new ReloadBehavior<T>(), {
                        fromBackend: false,
                        items,
                        useBearerAuthentication: false
                    });


                    // Sort
                    if (!__.IsNullOrUndefined(value?.orderby)) {
                        const sortDirection: number = value.orderby[0] === '-' ? -1 : 1;
                        const sortBy: string = value.orderby.replace(/^-/, '');
                        this._items.sort((a: T, b: T) => {

                            if (!__.IsNullOrUndefined((a as any)['variations']) && !__.IsNullOrUndefined((b as any)['variations'])) {

                                // Get track variation
                                const aVariation: TrackVariation = (a as any)['variations'].find((trackVariation: TrackVariation) => trackVariation.isOriginal === true);
                                const bVariation: TrackVariation = (b as any)['variations'].find((trackVariation: TrackVariation) => trackVariation.isOriginal === true);

                                // Sort for tracks numerical values
                                if (typeof (aVariation as any).sortBy === 'number' && typeof (bVariation as any).sortBy === 'number') {
                                    if ((aVariation as any).sortBy < (bVariation as any).sortBy) {
                                        return -1 * sortDirection;
                                    } else if ((aVariation as any).sortBy > (bVariation as any).sortBy) {
                                        return 1 * sortDirection;
                                    } else {
                                        return 0;
                                    }
                                }

                                // Sort by values alphabetically for displayName and genres
                                switch (sortBy) {
                                    case TrackHeader.Title:
                                        return sortDirection * aVariation.displayName.localeCompare(bVariation.displayName);
                                    case TrackHeader.Genre:
                                        if (aVariation.genres?.length > 0 && bVariation.genres?.length > 0) {
                                            return sortDirection * aVariation.genres[0].displayName.localeCompare(bVariation.genres[0].displayName);
                                        }
                                        if (aVariation.genres?.length === 0) {
                                            return -1 * sortDirection;
                                        }
                                        return sortDirection;
                                    case TrackHeader.Length:
                                        return this.sortNumerically(aVariation.length, bVariation.length, sortDirection);
                                    case TrackHeader.EnergyLevel:
                                        return this.sortNumerically(aVariation.energyLevel, bVariation.energyLevel, sortDirection);
                                    case TrackHeader.BPM:
                                        return this.sortNumerically(aVariation.bpm, bVariation.bpm, sortDirection);
                                }
                            }
                        });
                    }

                    // Paginator
                    if (!__.IsNullOrUndefined(this.database.paginator)) {
                        this.setItemsByPaginator(items);

                        if (this.database.paginator.length > 0 && items.length === 0) {
                            this.database.paginator.pageIndex = this.database.paginator.pageIndex - 1;
                            this.setItemsByPaginator(items);
                        }
                    }

                    return this.database.update(reloadBehavior);
                }),
            );
    }

    disconnect() { }

    /**
     * Triggers a reload of the data based on the provided reload behavior.
     * The data changed observable is returned to which the user can subscribe to
     * to be notified, when the reload was successfull.
     *
     * @param reloadBehavior The reload behavior that determines the reloading strategy
     */
    reloadData(reloadBehavior: ReloadBehavior<T> = null): Observable<T[]> {
        if (__.IsNullOrUndefined(reloadBehavior)) {
            reloadBehavior = new ReloadBehavior<T>();
        }
        this.database.reload$.next(reloadBehavior);
        return this.database.dataChanged$.pipe(takeUntil(this.database.disrupt$));
    }

    /// -----------------------------------------------------------------------------------------------------
    // @ PRIVATE METHODS
    // ------------------------------------------------------------------------------------------------------

    private sortNumerically(a: number, b: number, sortDirection: number): number {
        if (a < b) {
            return -1 * sortDirection;
        } else if (a > b) {
            return 1 * sortDirection;
        } else {
            return 0;
        }
    }

    private setItemsByPaginator(items: T[]) {
        if (this.database.paginator.length < this.database.paginator.pageIndex * this.database.paginator.pageSize) {
            this.database.paginator.pageIndex = this.database.paginator.pageIndex - 1;
        }
        for (let index = 0; index < this.database.paginator.length; index++) {
            if (index < (this.database.paginator.pageIndex + 1) * this.database.paginator.pageSize
                && index >= this.database.paginator.pageIndex * this.database.paginator.pageSize) {
                items.push(this._items[index]);
            }
        }
    }
}

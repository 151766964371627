import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { License, LicenseType } from '@app/shared/models/classes/License';
import { BasketService } from '@app/shared/services/basket.service';
import { LicensesService } from '@app/shared/services/licenses.service';

@Component({
    selector: 'sound-licenses-selector-detail',
    templateUrl: './licenses-selector-detail.component.html',
    styleUrls: ['./licenses-selector-detail.component.scss'],
    standalone: false
})
export class LicensesSelectorDetailComponent extends BaseComponent implements AfterViewInit {

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    Array = Array;

    isCustomLicense = LicensesService.isCustomLicense;

    licenseType = LicenseType;

    showMore: boolean = false;

    textOverflow: boolean = false;

    // -----------------------------------------------------------------------------------------------------
    // @ OUTPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Output() addLicenseClicked: EventEmitter<void> = new EventEmitter<void>();

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Input() currentIndex: number = 0;

    @Input() license: License;

    @Input() total: number = 0;

    @Input() withTax: number = 0;

    // -----------------------------------------------------------------------------------------------------
    // @ VIEW CHILDS
    // -----------------------------------------------------------------------------------------------------

    @ViewChild('licenseDetails') licenseDetails: ElementRef

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        public basketService: BasketService
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ LIFE CYCLE HOOKS
    // -----------------------------------------------------------------------------------------------------

    ngAfterViewInit(): void {
        this.isTextOverflow(this.licenseDetails.nativeElement);
    }

    /**
    * Check the element if it is text-overflow
    * @param element 
    */
    private isTextOverflow(element: any) {
        if (element) {
            this.textOverflow = element.offsetHeight < element.scrollHeight;
        } else {
            this.textOverflow = false;
        }
    }
}

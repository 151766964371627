import { __ } from '@app/shared/functions/object.functions';
import { TrackSort } from '../enums/TrackSorts.enum';

export class Sort {
    value: string;
    displayName: string;
    name: string;
}
export class TrackSortsHelper {
    static TrackSorts: any[] = [
        {
            value: TrackSort.None,
            displayName: 'Tracks.None',
        },
        {
            displayName: 'Tracks.Newest',
            value: TrackSort.Newest,
            name: 'isNewest',
        },
        {
            displayName: 'Tracks.Oldest',
            value: TrackSort.Oldest,
            name: 'isOldest'
        },
        {
            displayName: 'Tracks.BestsellerLastMonth',
            value: TrackSort.BestsellerLastMonth,
            name: 'isBestsellerLastMonth',
        },
        {
            displayName: 'Tracks.BestsellerLastYear',
            value: TrackSort.BestsellerLastYear,
            name: 'isBestsellerLastYear',
        },
        {
            displayName: 'Tracks.BestsellerAllTime',
            value: TrackSort.BestsellerAllTime,
            name: 'isBestsellerAllTime',
        }
    ];

    static GetTrackSortsByDisplayNameOrNull(displayName: string): any {
        const result = TrackSortsHelper.TrackSorts.find((range: any) => range.displayName === displayName);

        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }

        return result;
    }

    static GetTrackSortsByValueOrNull(value: string): any {
        const result = TrackSortsHelper.TrackSorts.find((range: any) => range.value === value);

        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }

        return result;
    }

    static GetTrackSortsByNameOrNull(name: string): any {
        const result = TrackSortsHelper.TrackSorts.find((range: any) => range.name === name);

        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }

        return result;
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { I18nService } from '@app/core/i18n.service';
import { AddNewProjectDialogComponent, AddNewProjectDialogData } from '@app/projects/shared/add-new-project-dialog/add-new-project-dialog.component';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { Project } from '@app/shared/models/classes/Project';
import { ProjectsService } from '@app/shared/services/projects.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';

export class AddToProjectDialogData {
    trackId: string;
    projectIds: string[];
}

@Component({
    selector: 'sound-add-to-project-dialog',
    templateUrl: './add-to-project-dialog.component.html',
    styleUrls: ['./add-to-project-dialog.component.scss'],
    standalone: false
})
export class AddToProjectDialogComponent extends BaseComponent implements OnInit {

    /// -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    items: Project[] = [];

    projectsAdded: string[] = [];

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private projectsService: ProjectsService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: AddToProjectDialogData,
        public dialogRef: MatDialogRef<AddToProjectDialogComponent>,
        private toastr: ToastrService,
        private i18nService: I18nService,
        private router: Router
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.getProjects();

        this.dialogRef.disableClose = false;
    }

    /// -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // ------------------------------------------------------------------------------------------------------

    getTotalLengthTime(project: Project): string {
        return Project.getTotalLength(project.totalLength);
    }

    /**
     * Navigate to project.
     * Disables default on click event and prevent event bubbling
     * @param event Mouse click event
     * @param project The project
     */
    navigateToProject(event: MouseEvent, project: Project): void {
        event.preventDefault();
        event.stopPropagation();
        this.router.navigateByUrl(this.i18nService.getAbsoluteUrlWithCurrentLanguage(`${AppRoute.MyProjects}/${project.id}`));
        this.dialogRef.close();
    }

    addToProject(project: Project): void {
        if (this.getIsLoadingByProject(project) !== true && this.getIsIncludedByProject(project) === false) {
            project['isLoading'] = true;

            super.addSubscription(
                this.projectsService
                    .addTracksToProjectByIds(project?.id, [{ trackId: this.data?.trackId }])
                    .subscribe({
                        next: (updatedProject: Project) => {
                            project['isLoading'] = false;
                            project['isIncluded'] = true;

                            project.tracksCount = project.tracksCount + 1;
                            this.data.projectIds.push(project.id);

                            this.projectsAdded.push(project.id);
                            this.getProjects();

                            this.toastr.success(this.translateService.instant('MyProjects.The song has been successfully added to this project'));
                        },
                        error: (error) => {
                            project['isLoading'] = false;
                            switch (error?.error?.code?.internalCode) {
                                case '1049':
                                    project['isIncluded'] = true;
                                    this.toastr.error(this.translateService.instant('MyProjects.The song has already been added to this project'));
                                    break;
                                default:
                                    this.toastr.error(this.translateService.instant('MyProjects.The song has already been added to this project'));
                                    break;
                            }
                        }
                    })
            );
        }
    }

    openAddNewProjectDialog(): void {
        super.addSubscription(
            this.dialog.open(AddNewProjectDialogComponent, {
                data: Object.assign(new AddNewProjectDialogData(), {
                }),
                maxWidth: '58.571rem'
            }).afterClosed()
                .subscribe({
                    next: (success: boolean) => {

                        if (success === true) {
                            // Fetch projects again
                            this.getProjects();
                        }
                    }
                })
        );
    }

    getIsIncludedByProject(project: Project): boolean {
        return project['isIncluded'] || this.data.projectIds.findIndex(q => q === project.id) > -1;
    }

    getIsLoadingByProject(project: Project): boolean {
        return project['isLoading'] === true;
    }

    /// -----------------------------------------------------------------------------------------------------
    // @ PRIVATE METHODS
    // ------------------------------------------------------------------------------------------------------

    private getProjects(): void {

        this.isLoading = true;

        super.addSubscription(
            this.projectsService
                .getAll('type=project&orderby=-modified')
                .pipe(
                    finalize(() => {
                        this.isLoading = false;
                    })
                )
                .subscribe({
                    next: (projects: Project[]) => {
                        this.items = projects; //projects.filter(q => q.userId == this.authenticationService?.user?.id);
                    }
                })
        );
    }
}

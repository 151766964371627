export enum MusicalKeyType {
    Undefined = 'Undefined',
    AMinor = 'AMinor',
    EMinor = 'EMinor',
    BMinor = 'BMinor',
    FsMinor = 'FsMinor',
    CsMinor = 'CsMinor',
    GsMinor = 'GsMinor',
    DsMinor = 'DsMinor',
    BbMinor = 'BbMinor',
    FMinor = 'FMinor',
    CMinor = 'CMinor',
    GMinor = 'GMinor',
    DMinor = 'DMinor',
    CMajor = 'CMajor',
    GMajor = 'GMajor',
    DMajor = 'DMajor',
    AMajor = 'AMajor',
    EMajor = 'EMajor',
    BMajor = 'BMajor',
    FsMajor = 'FsMajor',
    DbMajor = 'DbMajor',
    AbMajor = 'AbMajor',
    EbMajor = 'EbMajor',
    BbMajor = 'BbMajor',
    FMajor = 'FMajor',
}
import EventEmitter from './event-emitter.js';
class Player extends EventEmitter {
  constructor(options) {
    super();
    if (options.media) {
      this.media = options.media;
    } else {
      this.media = document.createElement('audio');
    }
    // Controls
    if (options.mediaControls) {
      this.media.controls = true;
    }
    // Autoplay
    if (options.autoplay) {
      this.media.autoplay = true;
    }
    // Speed
    if (options.playbackRate != null) {
      this.onceMediaEvent('canplay', () => {
        if (options.playbackRate != null) {
          this.media.playbackRate = options.playbackRate;
        }
      });
    }
  }
  onMediaEvent(event, callback, options) {
    this.media.addEventListener(event, callback, options);
    return () => this.media.removeEventListener(event, callback);
  }
  onceMediaEvent(event, callback) {
    return this.onMediaEvent(event, callback, {
      once: true
    });
  }
  getSrc() {
    return this.media.currentSrc || this.media.src || '';
  }
  revokeSrc() {
    const src = this.getSrc();
    if (src.startsWith('blob:')) {
      URL.revokeObjectURL(src);
    }
  }
  setSrc(url, blob) {
    const src = this.getSrc();
    if (src === url) return;
    this.revokeSrc();
    const newSrc = blob instanceof Blob ? URL.createObjectURL(blob) : url;
    this.media.src = newSrc;
    this.media.load();
  }
  destroy() {
    this.media.pause();
    this.revokeSrc();
    this.media.src = '';
    // Load resets the media element to its initial state
    this.media.load();
  }
  /** Start playing the audio */
  play() {
    return this.media.play();
  }
  /** Pause the audio */
  pause() {
    this.media.pause();
  }
  /** Check if the audio is playing */
  isPlaying() {
    return this.media.currentTime > 0 && !this.media.paused && !this.media.ended;
  }
  /** Jumpt to a specific time in the audio (in seconds) */
  setTime(time) {
    this.media.currentTime = time;
  }
  /** Get the duration of the audio in seconds */
  getDuration() {
    return this.media.duration;
  }
  /** Get the current audio position in seconds */
  getCurrentTime() {
    return this.media.currentTime;
  }
  /** Get the audio volume */
  getVolume() {
    return this.media.volume;
  }
  /** Set the audio volume */
  setVolume(volume) {
    this.media.volume = volume;
  }
  /** Get the audio muted state */
  getMuted() {
    return this.media.muted;
  }
  /** Mute or unmute the audio */
  setMuted(muted) {
    this.media.muted = muted;
  }
  /** Get the playback speed */
  getPlaybackRate() {
    return this.media.playbackRate;
  }
  /** Set the playback speed, pass an optional false to NOT preserve the pitch */
  setPlaybackRate(rate, preservePitch) {
    // preservePitch is true by default in most browsers
    if (preservePitch != null) {
      this.media.preservesPitch = preservePitch;
    }
    this.media.playbackRate = rate;
  }
  /** Get the HTML media element */
  getMediaElement() {
    return this.media;
  }
  /** Set a sink id to change the audio output device */
  setSinkId(sinkId) {
    // See https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId
    const media = this.media;
    return media.setSinkId(sinkId);
  }
}
export default Player;
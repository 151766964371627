import { Component, Input } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';

@Component({
    selector: 'sound-energy-levels',
    templateUrl: './energy-levels.component.html',
    styleUrls: ['./energy-levels.component.scss'],
    standalone: false
})
export class EnergyLevelsComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  levelCount: number[] = [];

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _energyLevel: number;

  @Input()
  public get energyLevel(): number {
    return this._energyLevel;
  }
  public set energyLevel(value: number) {
    if (this._energyLevel !== value) {
      this._energyLevel = value;
      this.levelCount = Array.from(Array(Math.ceil((value <= 1 ? value * 100 : value) / 10)).keys());
    }
  }

}

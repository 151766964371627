import { __ } from '@app/shared/functions/object.functions';
import { Header } from '../classes/Header';
import { HeaderSort } from '../enums/HeaderSort.enum';

export enum TrackHeader {
    Actions = 'actions',
    Image = 'image',
    Title = 'variations.displayName',
    Variations = 'variations',
    BPM = 'variations.bpm',
    Genre = 'variations.genres.genreTranslations.displayName',
    Waveform = 'waveform',
    Status = 'variations.status',
    PublishingDate = 'variations.publishingDate',
    Length = 'variations.length',
    EnergyLevel = 'variations.energyLevel',
    EndActions = 'end-actions',
    Select = 'select',
}

export class TrackHeadersHelper {
    static TrackHeaders: Header[] = [
        {
            value: TrackHeader.Actions,
            displayName: '',
            sort: HeaderSort.Disabled
        },
        {
            value: TrackHeader.Image,
            displayName: '',
            sort: HeaderSort.Disabled
        },
        {
            value: TrackHeader.Title,
            displayName: 'Tracks.Title',
            sort: HeaderSort.None
        },
        {
            value: TrackHeader.Variations,
            displayName: '',
            sort: HeaderSort.Disabled
        },
        {
            value: TrackHeader.BPM,
            displayName: 'Tracks.BPM',
            sort: HeaderSort.None
        },
        {
            value: TrackHeader.Genre,
            displayName: 'Tracks.Genre',
            sort: HeaderSort.None
        },
        {
            value: TrackHeader.Waveform,
            displayName: '',
            sort: HeaderSort.Disabled
        },
        {
            value: TrackHeader.Status,
            displayName: 'Tracks.Status',
            sort: HeaderSort.None
        },
        {
            value: TrackHeader.PublishingDate,
            displayName: 'Tracks.Date',
            sort: HeaderSort.None
        },
        {
            value: TrackHeader.Length,
            displayName: 'Tracks.Length',
            sort: HeaderSort.None
        },
        {
            value: TrackHeader.EnergyLevel,
            displayName: 'Tracks.Energy Level',
            sort: HeaderSort.None
        },
        {
            value: TrackHeader.EndActions,
            displayName: '',
            sort: HeaderSort.Disabled
        },
        {
            value: TrackHeader.Select,
            displayName: 'Tracks.Select all',
            sort: HeaderSort.Disabled
        },
    ];

    static GetTrackHeadersByNameOrNull(displayName: string): Header {
        const result = TrackHeadersHelper.TrackHeaders.find((header: Header) => header.displayName === displayName);

        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }

        return result;
    }

    static GetTrackHeadersByValueOrNull(value: string): Header {
        const result = TrackHeadersHelper.TrackHeaders.find((header: Header) => header.value === value);

        if (__.IsNullOrUndefinedOrEmpty(result)) {
            return null;
        }

        return result;
    }

    // Getting headers for home without [status, publishingDate]
    static GetTrackHeadersForHome(): Header[] {
        const tracksHeader = TrackHeadersHelper.TrackHeaders.filter((header: Header) =>
            [TrackHeader.PublishingDate, TrackHeader.Status, TrackHeader.Select].findIndex(q => q === header.value) === -1);
        const clonedTracksHeader: Header[] = [];
        for (const header of tracksHeader) {
            clonedTracksHeader.push(Object.assign({}, header));
        }
        return clonedTracksHeader;
    }

    // Getting headers for curator without [actions, bpm, genre, energyLevel]
    static GetTrackHeadersForCurator(): Header[] {
        const tracksHeader = TrackHeadersHelper.TrackHeaders.filter((header: Header) =>
            [TrackHeader.Actions, TrackHeader.BPM, TrackHeader.Genre, TrackHeader.EnergyLevel]
                .findIndex(q => q === header.value) === -1);
        const clonedTracksHeader: Header[] = [];
        for (const header of tracksHeader) {
            clonedTracksHeader.push(Object.assign({}, header));
        }
        return clonedTracksHeader;
    }

    // Getting headers for creator without [actions, bpm, genre, energyLevel, select]
    static GetTrackHeadersForCreator(): Header[] {
        const tracksHeader = TrackHeadersHelper.TrackHeaders.filter((header: Header) =>
            [TrackHeader.Actions, TrackHeader.BPM, TrackHeader.Genre, TrackHeader.EnergyLevel, TrackHeader.Select]
                .findIndex(q => q === header.value) === -1);
        const clonedTracksHeader: Header[] = [];
        for (const header of tracksHeader) {
            clonedTracksHeader.push(Object.assign({}, header));
        }
        return clonedTracksHeader;
    }
    
    // Getting headers for admin dashbord/orders
    static GetTrackHeadersForAdmin(): Header[] {
        const tracksHeader = TrackHeadersHelper.TrackHeaders.filter((header: Header) =>
            [TrackHeader.Actions, TrackHeader.PublishingDate, TrackHeader.EndActions, TrackHeader.Status]
                .findIndex(q => q === header.value) === -1);
        const clonedTracksHeader: Header[] = [];
        for (const header of tracksHeader) {
            clonedTracksHeader.push(Object.assign({}, header));
        }
        return clonedTracksHeader;
    }

}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';
import { Track, TrackVariation } from '@app/shared/models/classes/Track';
import { BadgeType } from '@app/shared/models/enums/BadgeType.enum';

@Component({
    selector: 'sound-tracks-row',
    templateUrl: './tracks-row.component.html',
    styleUrls: ['./tracks-row.component.scss'],
    standalone: false
})
export class TracksDetailsComponent extends BaseComponent implements OnInit {

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    originalVariation: TrackVariation;

    showVariations: UntypedFormControl = new UntypedFormControl(false);

    BadgeType = BadgeType;

    showEditForm: boolean = false;

    showEditVariationForms: any[] = [];

    isCuratorRoute: boolean = false;

    isCreatorRoute: boolean = false;

    // -----------------------------------------------------------------------------------------------------
    // @ OUTPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Output() deleteClicked: EventEmitter<Track> = new EventEmitter<Track>();

    @Output() deletedTrack: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() publishedTrack: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() removedFromPlaylist: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() submittedTrack: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() addAllTracksToPlaylist: EventEmitter<{ id: string, index: number }> = new EventEmitter<{ id: string, index: number }>();

    @Output() toggledFavorite: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() trackVariationSaved: EventEmitter<boolean> = new EventEmitter<boolean>();

    // -----------------------------------------------------------------------------------------------------
    // @ INPUT VARIABLES
    // -----------------------------------------------------------------------------------------------------

    @Input() showInfo: boolean = false;

    @Input() viewWidth: number;

    @Input() selectable: boolean = false;

    @Input() isDeletable: boolean = false;

    @Input() shouldLoadWaveform: boolean = true;

    @Input() shouldAddAllTracksToPlaylist: boolean = false;

    @Input() shouldResetPlaylist: boolean = false;

    @Input() descriptionColSpan: number = 8;

    @Input() trackInPlaylistComment: string;

    @Input() projectId: string;

    @Input() permissions: { canRate: boolean; canComment: boolean };

    private _trackListId: string;

    @Input()
    public set trackListId(trackListId: string) {
        this._trackListId = trackListId;
    }
    public get trackListId(): string {
        return this._trackListId;
    }

    private _item: Track;

    @Input()
    get item(): Track {
        return this._item;
    }
    set item(value: Track) {
        this._item = value;

        if (!__.IsNullOrUndefined(value)) {
            this.setItem();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private router: Router
    ) {
        super();

        this.display = 'table-row-group';

        if (this.router.url.includes(AppRoute.Creator)) { this.isCreatorRoute = true; }
        if (this.router.url.includes(AppRoute.Curator)) { this.isCuratorRoute = true; }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ LIFE CYCLE HOOKS
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {
        this.setItem();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    getOriginalTrackVariationId(): string {
        return this.item.variations.find(q => q.isOriginal === true).id;
    }

    toggleOriginalVariationEditForm(): void {
        if (this.item.variations.length > 1) {
            this.showVariations.setValue(true);
            this.toggleEditFormByItem(this.originalVariation);
            return;
        }
        this.showEditForm = !this.showEditForm;
    }

    toggleEditFormByItem(item: TrackVariation): void {
        this.showEditVariationForms.map(q => {
            if (q.id === item.id) {
                q.isShown = !q.isShown;
            }
        });
    }

    getShowEditFormByItem(item: TrackVariation): boolean {
        const variation = this.showEditVariationForms.find(q => q.id === item.id);
        return variation?.isShown;
    }

    saveTrackVariation(): void {
        this.showEditForm = !this.showEditForm;
        this.trackVariationSaved.emit(true);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE METHODS
    // -----------------------------------------------------------------------------------------------------

    setItem(): void {
        if (!this.IsNullOrUndefinedOrEmpty(this.item)) {
            this.originalVariation = this.item.variations.find(q => q.isOriginal === true);

            this.item.variations = this.item.variations.sort((a, b) => (a.isOriginal > b.isOriginal ? -1 : 1));

            this.item.variations.map((variation: TrackVariation) => {
                this.showEditVariationForms.push({ isShown: false, id: variation.id });
            });
        }
    }
}
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { TranslateService } from '@ngx-translate/core';

export class AddNewProjectDialogData {
  projectId: string;
}

@Component({
    selector: 'sound-add-new-project-dialog',
    templateUrl: './add-new-project-dialog.component.html',
    styleUrls: ['./add-new-project-dialog.component.scss'],
    standalone: false
})
export class AddNewProjectDialogComponent extends BaseComponent implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // ------------------------------------------------------------------------------------------------------

  title: string;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddNewProjectDialogData,
    public dialogRef: MatDialogRef<AddNewProjectDialogComponent>,
    private translateService: TranslateService
  ) {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    this.title = this.translateService.instant('MyProjects.Create New Project');

    if (!this.IsNullOrUndefinedOrEmpty(this.data.projectId)) {
      this.title = this.translateService.instant('MyProjects.Edit Project');
    }
  }

}

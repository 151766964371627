<!-- DOWNLOAD BUTTON -->
<div class="download-button" [matMenuTriggerFor]="actions">

  <!-- DOWNLOAD -->
  <mat-icon class="cursor-pointer" [ngClass]="['large']" [svgIcon]="'download'"></mat-icon>

  <mat-menu #actions="matMenu" backdropClass="download-button__menu-background" (closed)="toolTipDisabled = true">
    <div class="flex flex-row gap-3 py-2 px-4" (click)="$event.stopPropagation()">

      <!-- MP3-->
      <mat-icon class="cursor-pointer" [ngClass]="['large']" [svgIcon]="'mp3'" (click)="download(TrackResolution.MP3)"
        [matTooltipDisabled]="toolTipDisabled" [matTooltipPosition]="'above'"
        (mouseleave)="tooltip.hide()" 
        [matTooltip]="'Tracks.You need to be logged in' | translate" #tooltip="matTooltip">
      </mat-icon>

      <!-- WAV -->
      <mat-icon [ngClass]="{'large cursor-pointer': true, 'disabled': isAllowedToDownloadHiRes() === false}"
        [svgIcon]="'wav'" (click)="download(TrackResolution.WAV)"></mat-icon>

    </div>
  </mat-menu>
</div>
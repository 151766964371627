<div class="license-selector-detail__container flex flex-col sm:flex-row">
    <div class="w-full" style="display: flex;
    align-items: flex-start;
    gap: 2.286rem;">
        <div class="license-selector-detail__description">
            <div class="license-selector-detail__header">
                <h3> {{ license.displayName }}</h3>
                <div *ngIf="license.type === licenseType.Standard">
                    <div class="license-selector-detail__dots">
                        <div *ngFor="let value of Array(total); let index = index"
                            [ngClass]="{'license-selector-detail__dot': true, 'license-selector-detail__dot--active': index < license.level}">
                        </div>
                    </div>
                </div>
            </div>
            <div class="license-selector-detail__items-container hidden sm:block">
                <div class="license-selector-detail__item">
                    <div #licenseDetails
                        [ngClass]="showMore == false? 'license-selector-detail__item__hide' : 'license-selector-detail__item__show' "
                        [innerHTML]="license.licenseDetails | safe:'html'"></div>
                </div>
                <a (click)="showMore = !showMore" *ngIf="!IsNullOrUndefined(license.licenseDetails) && textOverflow">
                    {{(showMore ? 'License.Show Less' : 'License.Show More') | translate}}
                </a>
            </div>
        </div>
        <div class="license-selector-detail__actions">
            <sound-button-default [colorClass]="'default'" (onClick)="addLicenseClicked.emit()" [icon]="'plus_square'"
                [isDisabled]="!IsNullOrUndefined(basketService.getBasketLicenseType()) && basketService.getBasketLicenseType() !== license.type"
                [matTooltip]="'License.You can only purchase items of one type of license at a time' | translate"
                [matTooltipPosition]="'above'"
                [matTooltipDisabled]="basketService.getBasketLicenseType() === license.type || IsNullOrUndefined(basketService.getBasketLicenseType())"
                [displayText]="(isCustomLicense(license) ? 'License.Select License' : 'License.Add License') | translate">
            </sound-button-default>
            <div class="license-selector-detail__price" *ngIf="!isCustomLicense(license)">
                <h1 [class]="'license-selector-detail__price__amount'">
                    {{ license.price?.amount / 100 | defaultCurrency:license.price?.currency }}
                </h1>
                <p [class]="'license-selector-detail__price__vat'"
                    *ngIf="withTax > 0 && license.price?.showTax === true">
                    {{ 'Basket.Mwst' | translate: {tax: withTax} }}
                </p>
            </div>
        </div>
    </div>

    <div class="license-selector-detail__items-container block sm:hidden">
        <div class="license-selector-detail__item">
            <div #licenseDetails
                [ngClass]="showMore == false? 'license-selector-detail__item__hide' : 'license-selector-detail__item__show' "
                [innerHTML]="license.licenseDetails | safe:'html'"></div>
        </div>
        <a (click)="showMore = !showMore" *ngIf="!IsNullOrUndefined(license.licenseDetails) && textOverflow">
            {{(showMore ? 'License.Show Less' : 'License.Show More') | translate}}
        </a>
    </div>

</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { FileContainer } from '@app/shared/components/files/models/FileContainer';
import { Track } from '@app/shared/models/classes/Track';
import { TaskStatus, WebhookTask } from '@app/shared/models/classes/WebhookTask';
import { SimilaritySearchService } from '@app/shared/services/local/similarity-search.service';
import { TasksService } from '@app/shared/services/tasks.service';
import { TracksService } from '@app/shared/services/tracks.service';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { concatMap, delay, interval, map, Observable, of, switchMap } from 'rxjs';

export class SimilaritySearchDialogData {
  spotifyId?: string;
  mp3?: FileContainer;
  type: 'Mp3' | 'Spotify';
}

@Component({
    selector: 'sound-similarity-search-dialog',
    templateUrl: './similarity-search-dialog.component.html',
    styleUrls: ['./similarity-search-dialog.component.scss'],
    standalone: false
})
export class SimilaritySearchDialogComponent extends BaseComponent implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  progress: number = 1;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SimilaritySearchDialogData,
    public dialogRef: MatDialogRef<SimilaritySearchDialogComponent>,
    private similaritySearchService: SimilaritySearchService,
    private tracksService: TracksService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private tasksService: TasksService
  ) {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    switch (this.data.type) {
      case 'Mp3':
        if (!this.IsNullOrUndefined(this.data.mp3)) {
          this.uploadMp3(this.data.mp3);
        }
        break;
      case 'Spotify':
        super.addSubscription(
          this.tracksService.getAll(`variationsStatus=Online&similarityType=Spotify&similarityId=${this.data.spotifyId}`, 0, 20).pipe(
            switchMap((result: Track[]) => {
              if (typeof(result) === typeof('string')) {
                return this.pollTaskById(result as unknown as string, moment());
              }

              return of(true);
            })
          ).subscribe({
            next: (result: any) => {
              this.ngOnDestroy();
              this.progress = 100;
  
              setTimeout(() => {
                this.dialogRef.close(this.data.spotifyId);
              }, 500);
            },
            error: (error: any) => {
              this.toastr.error(
                this.translateService.instant('An error that should not happened has happened. Sorry for that. Please contact the administrator')
              );
              this.dialogRef.close(null);
            }
          })
        );
        break;
    }

    super.addSubscription(
      interval(1000).subscribe(() => {
        if (this.progress < 60) {
          this.progress = this.progress + 2;
        }
        if (this.progress >= 60 && this.progress < 80) {
          this.progress = this.progress + 1;
        }
        if (this.progress >= 80 && this.progress < 90) {
          this.progress = this.progress + 0.5;
        }
        if (this.progress >= 90 && this.progress < 100) {
          this.progress = this.progress + 0.1;
        }
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  uploadMp3(mp3: FileContainer): void {

    let cyaniteId: string = '';

    super.addSubscription(
      this.tracksService
        .uploadMp3ForSimilaritySearch(mp3)
        .pipe(
          switchMap(
            (task: { cyaniteId: string, taskId: string }) => {

              this.similaritySearchService.cyaniteId = task.cyaniteId;
              this.similaritySearchService.mp3 = mp3;
              cyaniteId = task.cyaniteId;

              return this.pollTaskById(task.taskId, moment());
            }
          )
        )
        .subscribe({
          next: (response: any) => {
            this.ngOnDestroy();
            this.progress = 100;

            setTimeout(() => {
              this.dialogRef.close(cyaniteId);
            }, 500);
          },
          error: (error: any) => {
            this.toastr.error(
              this.translateService.instant('An error that should not happened has happened. Sorry for that. Please contact the administrator')
            );
            this.dialogRef.close(null);
          }
        })
    );
  }

  private pollTaskById(taskId: string, startTime: Moment): Observable<any> {
    if (moment().isBefore(startTime.add(3, 'minutes'))) {
      return this.tasksService
        .getById(taskId)
        .pipe(
          delay(2000),
          concatMap((task: WebhookTask) => {
            if (task.status === TaskStatus.Completed) {
              return of(task);
            }
            if (task.status === TaskStatus.Erroneous) {
              console.log('ERROR');
              // TODO: Display error message
              return of(task);
            }

            return this.pollTaskById(taskId, startTime);
          })
        );
    }

    this.dialogRef.close(null);
  }
}



import { __ } from "@app/shared/functions/object.functions";
import { Role } from "../classes/Role";
import { PlaylistPublicationStatus } from "../classes/Playlist";

export class PlaylistPublicationStatusHelper {
    static PublicationStatuses: any[] = [
      {
        displayName: 'Public',
        value: PlaylistPublicationStatus.Public
      },
      {
        displayName: 'Private',
        value: PlaylistPublicationStatus.Private
      }
    ];
  
    static GetExtendedPlaylistPublicationStatusByNameOrNull(displayName: string): any {
      const result = PlaylistPublicationStatusHelper.PublicationStatuses.find((status: any) => status.value === displayName);
      if (__.IsNullOrUndefinedOrEmpty(result)) {
        return null;
      }
      return result;
    }
  
    static GetExtendedPlaylistPublicationStatusByValueOrNull(value: Role | string): any {
      const result = PlaylistPublicationStatusHelper.PublicationStatuses.find((status: any) => status.value === value);
      if (__.IsNullOrUndefinedOrEmpty(result)) {
        return null;
      }
      return result;
    }
  }
<sound-list>
  <!-- HEADER -->
  <sound-list-header [class]="listTitleClass" [count]="showCount === true ? this.paginator.length : -1">
    {{ listTitle }}
  </sound-list-header>

  <!-- FILTERS -->
  <sound-list-filters class="tracks__filters">
    <!-- FILTERS UPPER CONTENT -->
    <sound-list-filters-upper-content *ngIf="showExtraFilters">
      <sound-list-filters-upper-content-row>
        <!-- SEARCH -->
        <jad-form-fields-text [class]="'chats__filters-content__search'" name="search"
          [control]="filtersForm.get('search')" [suffix]="'search'" [hasIconSuffix]="true" [suffixPosition]="'end'"
          [placeholder]="'Tracks.Search' | translate" [required]="false" [autofill]="'off'">
        </jad-form-fields-text>
        <!-- ARTIST -->
        <jad-form-fields-select class="ml-4" [width]="'8.571rem'" [multiple]="true"
          [control]="filtersForm.get('artistPseudonym')" [showLabel]="false" [label]="'Tracks.Artist' | translate"
          [placeholder]="'Tracks.Artist' | translate" [showSelectedValues]="false" [items]="artists">
        </jad-form-fields-select>
        <!-- GENRE -->
        <jad-form-fields-select class="ml-4" [width]="'8.571rem'" [multiple]="true"
          [control]="filtersForm.get('genreNormalizedName')" [showLabel]="false" [label]="'Tracks.Genre' | translate"
          [placeholder]="'Tracks.Genre' | translate" [showSelectedValues]="false" [items]="genres">
        </jad-form-fields-select>
        <!-- MOOD -->
        <jad-form-fields-select class="ml-4" [width]="'8.571rem'" [multiple]="true"
          [control]="filtersForm.get('moodNormalizedName')" [showLabel]="false" [label]="'Tracks.Mood' | translate"
          [placeholder]="'Tracks.Mood' | translate" [showSelectedValues]="false" [items]="moods">
        </jad-form-fields-select>
        <!-- INSTRUMENT -->
        <jad-form-fields-select class="ml-4" [width]="'10rem'" [multiple]="true"
          [control]="filtersForm.get('instrumentNormalizedName')" [showLabel]="false"
          [label]="'Tracks.Instrument' | translate" [placeholder]="'Tracks.Instrument' | translate"
          [showSelectedValues]="false" [items]="instruments">
        </jad-form-fields-select>
        <!-- STATUS -->
        <jad-form-fields-select class="ml-4" [width]="'8.571rem'" [multiple]="true"
          [control]="filtersForm.get('statusNormalizedName')" [showLabel]="false" [label]="'Tracks.Status' | translate"
          [placeholder]="'Tracks.Status' | translate" [showSelectedValues]="false" [items]="status">
        </jad-form-fields-select>
        <!-- THEME -->
        <jad-form-fields-select class="ml-4" [width]="'8.571rem'" [multiple]="true"
          [control]="filtersForm.get('themeNormalizedName')" [showLabel]="false" [label]="'Tracks.Theme' | translate"
          [placeholder]="'Tracks.Theme' | translate" [showSelectedValues]="false" [items]="themes">
        </jad-form-fields-select>
      </sound-list-filters-upper-content-row>

      <sound-list-filters-upper-content-row>
        <!-- FILTERS -->
        <sound-filters></sound-filters>
      </sound-list-filters-upper-content-row>

    </sound-list-filters-upper-content>
    <!-- FILTERS PAGE SIZE SELECTOR -->
    <sound-list-filters-paginator>
      <sound-paginator [paginator]="paginator" [showEnd]="false"></sound-paginator>
    </sound-list-filters-paginator>
    <!-- FILTERS CONTENT -->
    <sound-list-filters-content>
      <div
        class="flex flex-col sm:flex-row md:flex-col lg:flex-row sm:space-x-8 md:space-x-0 lg:space-x-8 sm:items-center md:items-start lg:items-center">
        <!-- FILTERS SONG INFO DISPLAY -->
        <jad-form-fields-checkbox [control]="songInfoControl">
          {{ 'Tracks.Song info' | translate }}
        </jad-form-fields-checkbox>
        <!-- FILTERS SORT -->
        <jad-form-fields-select class="lg:ml-8 mt-1 md:mt-0" [width]="'14.857rem'" [control]="sortControl"
          [showLabel]="false" [label]="'Tracks.Sort' | translate" [placeholder]="'Tracks.Sort' | translate"
          [items]="TrackSorts">
        </jad-form-fields-select>
      </div>
    </sound-list-filters-content>
  </sound-list-filters>

  <!-- TABLE -->
  <sound-list-table [headers]="trackHeaders" [items]="dataSource?.database?.dataChanged$ | async" [listId]="trackListId"
    [isLoadingItems]="isLoading"
    [noEntitiesFoundMessage]="(isFiltered ? 'Tracks.There are no tracks that fit your search' : 'Tracks.No tracks found') | translate">
    <!-- ROW CONTENT -->
    <ng-template #rowTemplate let-row>
      <sound-tracks-row class="tracks__row" [viewWidth]="viewWidth" [isDeletable]="isDeletable"
        (publishedTrack)="dataSource.reloadData()" (submittedTrack)="dataSource.reloadData()"  [permissions]="permissions"
        (deletedTrack)="dataSource.reloadData()" (removedFromPlaylist)="dataSource.reloadData()"
        [shouldAddAllTracksToPlaylist]="true" (deleteClicked)="deleteClicked.emit(row?.item)" [selectable]="selectable"
        [showInfo]="songInfoControl.value" [trackListId]="trackListId" [item]="row?.item"
        (addAllTracksToPlaylist)="addAllTracksToPlaylist($event)" [descriptionColSpan]="descriptionColSpan" (trackVariationSaved)="dataSource.reloadData()" [projectId]="playlistId">
      </sound-tracks-row>
    </ng-template>

    <!-- ROW TEMPLATE -->
    <ng-container *soundListTableRowDirective="let item">
      <ng-container
        *ngTemplateOutlet="rowTemplate; context: {$implicit: { item : item?.item, index: item?.index , headers: trackHeaders } }">
      </ng-container>
    </ng-container>

    <!-- LOADING TEMPLATE -->
    <ng-container *soundListTableLoadingIndicator>
      <ng-container *ngFor="let item of Array(paginator?.pageSize)">
        <sound-tracks-row class="tracks__row" [isLoading]="true" [viewWidth]="viewWidth" [selectable]="selectable"
          [trackListId]="trackListId" [item]="item">
        </sound-tracks-row>
      </ng-container>
    </ng-container>

  </sound-list-table>

  <!-- ACTIONS -->
  <sound-list-actions *ngIf="showActions">

    <div class="flex flex-row justify-end mb-10 mt-2">

      <!-- DELETE SELECTED -->
      <sound-button-default [icon]="'trash'" (onClick)="deleteSelectedTracks()" [isLoading]="isDeleteLoading"
        [isDisabled]="selection.selected.length === 0 || isDeleteLoading === true"
        [displayText]=" 'Tracks.Delete selected' | translate">
      </sound-button-default>

      <!-- UNPUBLISH SELECTED -->
      <sound-button-default class="ml-4" [icon]="'reject'" (onClick)="unpublishSelectedTracks()"
        [isLoading]="isUnpublishLoading" [isDisabled]="selection.selected.length === 0 || isUnpublishLoading === true"
        [displayText]=" 'Tracks.Unpublish selected' | translate">
      </sound-button-default>

      <!-- PUBLISH SELECTED -->
      <sound-button-default class="ml-4" [colorClass]="'green'" [icon]="'check'" (onClick)="publishSelectedTracks()"
        [isLoading]="isPublishLoading" [isDisabled]="selection.selected.length === 0 || isPublishLoading === true"
        [displayText]=" 'Tracks.Publish selected' | translate">
      </sound-button-default>

    </div>

  </sound-list-actions>

  <!-- PAGINATOR PAGE SELECTOR -->
  <sound-list-page-selector>
    <sound-paginator [listenToUrlChanges]="true" [paginator]="paginator" [type]="'PageSelection'"
      [showEnd]="false"></sound-paginator>

    <!-- GENERAL PAGINATOR USED FOR SOUND-PAGINATOR DISPLAY -->
    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" style="display: none;"></mat-paginator>
  </sound-list-page-selector>
</sound-list>
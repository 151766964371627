<sound-dialog-header [title]="'MyProjects.Add to project' | translate" class="dialog-header" [icon]="'my_projects'"
    (emitCloseClick)="dialogRef.close(projectsAdded)"></sound-dialog-header>

<sound-dialog-content>
    <div #projectsContainer class="my-projects__container">

        <!-- PROJECTS -->
        <ng-container *ngIf="isLoading === false; else loadingIndicator;">
            <div class="my-projects__projects mb-6">
                <table class="my-projects__table">

                    <!-- PROJECT -->
                    <ng-container *ngFor="let item of items; let i = index;">

                        <tr class="my-projects__table__row"
                            [ngClass]="{'my-projects__table__row--included': getIsIncludedByProject(item) === true}"
                            (click)="addToProject(item)">

                            <!-- NAME -->
                            <td class="my-projects__table__row__cell my-projects__title">
                                {{ item?.displayName }}
                            </td>

                            <!-- MODIFIED -->
                            <td class="my-projects__table__row__cell my-projects__modified">
                                {{ item?.modified | date: 'dd.MM.yyyy' }}
                            </td>

                            <!-- SONGS -->
                            <td class="my-projects__table__row__cell my-projects__tracks-count">
                                {{ item?.tracksCount + ' Song' + (item?.tracksCount > 1 ? 's' : '') }}
                            </td>

                            <!-- LENGTH -->
                            <td class="my-projects__table__row__cell my-projects__length">
                                {{ getTotalLengthTime(item) }}
                            </td>

                            <!-- IS INCLUDED -->
                            <td class="my-projects__table__row__cell my-projects__actions">
                                <ng-container *ngIf="getIsIncludedByProject(item) === true">
                                    <mat-icon class="my-projects__action--included large" [svgIcon]="'check'">
                                    </mat-icon>
                                </ng-container>
                                <ng-container *ngIf="getIsLoadingByProject(item) === true">
                                    <div class="flex flex-row justify-end" style="margin-right: 10px;">
                                        <mat-spinner [diameter]="20"></mat-spinner>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="getIsLoadingByProject(item) === false">
                                    <mat-icon class="mat-icon small"
                                        [ngClass]="{'align-super': getIsIncludedByProject(item)}"
                                        [svgIcon]="'arrow-up-right-from-square-regular'"
                                        [matTooltipPosition]="'above'"
                                        [matTooltip]="'MyProjects.Open project' | translate" 
                                        (click)="navigateToProject($event, item)">
                                    </mat-icon>
                                </ng-container>
                            </td>
                        </tr>

                        <tr class="empty-row"></tr>

                    </ng-container>

                </table>
            </div>
        </ng-container>

        <!-- LOADING INDICATOR -->
        <ng-template #loadingIndicator>
            <div class="flex flex-row justify-center items-center mb-6">
                <mat-spinner [diameter]="20"></mat-spinner>
            </div>
        </ng-template>

        <!-- ACTIONS -->
        <div class="my-projects__actions">
            <!-- CREATE -->
            <sound-button-default class="my-projects__actions__create" [icon]="'plus_square'"
                [displayText]="'MyProjects.New project' | translate" [colorClass]="'default'"
                (onClick)="openAddNewProjectDialog()">
            </sound-button-default>
        </div>

    </div>
</sound-dialog-content>
<!-- FORM -->
<div class="track__form px-24 py-8">

  <!-- FORM GROUP -->
  <div class="grid grid-cols-3 grid-rows-1 gap-20" *ngIf="isLoading === false; else loading">
    <div class="track__form-group">

      <!-- MUSIC TITLE -->
      <jad-form-fields-text [control]="form.get('displayName')" [placeholder]="'Tracks.Title placeholder' | translate"
        [displayName]="'Tracks.Music Title' | translate" [required]="true"></jad-form-fields-text>

      <!-- DESCRIPTION DE -->
      <jad-form-fields-textarea [control]="form.get('descriptionDE')"
        [placeholder]="'Tracks.Description DE placeholder' | translate"
        [displayName]="'Tracks.Description DE' | translate"></jad-form-fields-textarea>

      <!-- DESCRIPTION EN -->
      <jad-form-fields-textarea [control]="form.get('descriptionEN')"
        [placeholder]="'Tracks.Description EN placeholder' | translate"
        [displayName]="'Tracks.Description EN' | translate"></jad-form-fields-textarea>

      <!-- MOOD -->
      <jad-form-fields-select name="mood" [control]="form.get('moods')" [required]="true" [width]="'100%'"
        [matTooltip]="'Tracks.This field cannot be modified by creators' | translate" [matTooltipPosition]="'above'"
        [matTooltipDisabled]="!isCreatorRoute" [disabled]="isCreatorRoute" [multiple]="true"
        [displayName]="'Tracks.Mood' | translate" [placeholder]="'Register.Select' | translate" [items]="moods">
      </jad-form-fields-select>

      <!-- THEME -->
      <jad-form-fields-select name="theme" [control]="form.get('themes')" [required]="false" [width]="'100%'"
        [matTooltip]="'Tracks.This field cannot be modified by creators' | translate" [disabled]="isCreatorRoute"
        [matTooltipPosition]="'above'" [matTooltipDisabled]="!isCreatorRoute" [multiple]="true"
        [displayName]="'Tracks.Theme' | translate" [placeholder]="'Register.Select' | translate" [items]="themes">
      </jad-form-fields-select>

    </div>
    <div class="track__form-group">

      <!-- GENRE -->
      <jad-form-fields-select name="genre" [control]="form.get('genres')" [required]="true" [width]="'100%'"
        [multiple]="true" [displayName]="'Tracks.Genre' | translate" [placeholder]="'Register.Select' | translate"
        [items]="genres">
      </jad-form-fields-select>

      <!-- SUBGENRE -->
      <jad-form-fields-select name="subGenre" [control]="form.get('subGenres')" [required]="false" [width]="'100%'"
        [disabled]="subGenres.length === 0" [multiple]="true" [displayName]="'Tracks.Subgenre' | translate"
        [placeholder]="'Register.Select' | translate" [items]="subGenres">
      </jad-form-fields-select>

      <!-- KEYWORDS DE -->
      <jad-form-fields-text [control]="form.get('keyWordsDE')"
        [placeholder]="'Tracks.Keywords DE placeholder' | translate" [displayName]="'Tracks.Keywords DE' | translate"
        [required]="false"></jad-form-fields-text>

      <!-- KEYWORDS EN -->
      <jad-form-fields-text [control]="form.get('keyWordsEN')"
        [placeholder]="'Tracks.Keywords EN placeholder' | translate" [displayName]="'Tracks.Keywords EN' | translate"
        [required]="false"></jad-form-fields-text>

      <!-- INSTRUMENT -->
      <jad-form-fields-select name="instrument" [control]="form.get('instruments')" [required]="false" [width]="'100%'"
        [matTooltip]="'Tracks.This field cannot be modified by creators' | translate" [disabled]="isCreatorRoute"
        [matTooltipPosition]="'above'" [matTooltipDisabled]="!isCreatorRoute" [multiple]="true"
        [displayName]="'Tracks.Instrument' | translate" [placeholder]="'Register.Select' | translate"
        [items]="instruments">
      </jad-form-fields-select>

      <!-- SOUNDS LIKE -->
      <jad-form-fields-text [control]="form.get('soundsLike')"
        [placeholder]="'Tracks.Sounds like placeholder' | translate" [displayName]="'Tracks.Sounds like' | translate"
        [required]="false"></jad-form-fields-text>

      <!-- VOCALS -->
      <jad-form-fields-select name="vocals" [control]="form.get('vocals')" [required]="false" [width]="'100%'"
        [multiple]="true" [matTooltip]="'Tracks.This field cannot be modified by creators' | translate"
        [disabled]="isCreatorRoute" [matTooltipPosition]="'above'" [matTooltipDisabled]="!isCreatorRoute"
        [displayName]="'Tracks.Vocals' | translate" [placeholder]="'Register.Select' | translate" [items]="vocals">
      </jad-form-fields-select>

    </div>
    <div class="track__form-group">

      <!-- LENGTH -->
      <jad-form-fields-text name="length" [control]="form.get('length')" [disabled]="true"
        [placeholder]="'Tracks.Length placeholder' | translate" [displayName]="'Tracks.Length' | translate"
        [required]="false"></jad-form-fields-text>

      <!-- BPM -->
      <jad-form-fields-text name="bpm" [control]="form.get('bpm')" [placeholder]="'Tracks.BPM placeholder' | translate"
        [matTooltip]="'Tracks.This field cannot be modified by creators' | translate" [disabled]="isCreatorRoute"
        [matTooltipPosition]="'above'" [matTooltipDisabled]="!isCreatorRoute" [displayName]="'Tracks.BPM' | translate"
        [required]="false"></jad-form-fields-text>

      <!-- ENERGY LEVEL -->
      <jad-form-fields-text name="energyLevel" [control]="form.get('energyLevel')" [required]="false"
        [matTooltip]="'Tracks.This field cannot be modified by creators' | translate" [disabled]="isCreatorRoute"
        [matTooltipPosition]="'above'" [matTooltipDisabled]="!isCreatorRoute"
        [displayName]="'Tracks.Energy Level' | translate" [placeholder]="'Register.Select' | translate">
      </jad-form-fields-text>

      <!-- KEY -->
      <jad-form-fields-select name="key" [control]="form.get('musicalKeyType')" [required]="true" [width]="'100%'"
        [matTooltip]="'Tracks.This field cannot be modified by creators' | translate" [disabled]="isCreatorRoute"
        [matTooltipPosition]="'above'" [matTooltipDisabled]="!isCreatorRoute" [displayName]="'Tracks.Key' | translate"
        [placeholder]="'Register.Select' | translate" [items]="keys">
      </jad-form-fields-select>

      <!-- BEATFREE -->
      <jad-form-fields-checkbox [control]="form.get('isBeatFree')" class="mb-6 mt-5" [required]="false"
        [matTooltip]="'Tracks.This field cannot be modified by creators' | translate" [disabled]="isCreatorRoute"
        [matTooltipPosition]="'above'" [matTooltipDisabled]="!isCreatorRoute">
        {{'Tracks.Beatfree' | translate}}
      </jad-form-fields-checkbox>

      <!-- INSTRUMENTAL -->
      <jad-form-fields-checkbox [control]="form.get('isInstrumental')" class="mb-6" [required]="false"
        [matTooltip]="'Tracks.This field cannot be modified by creators' | translate" [disabled]="isCreatorRoute"
        [matTooltipPosition]="'above'" [matTooltipDisabled]="!isCreatorRoute">
        {{'Tracks.Instrumental' | translate}}
      </jad-form-fields-checkbox>

      <!-- FEATURED -->
      <jad-form-fields-checkbox [control]="form.get('isFeatured')" class="mb-6" [required]="false" [disabled]="isCreatorRoute"
        [matTooltipPosition]="'above'" [matTooltipDisabled]="!isCreatorRoute"
        [matTooltip]="'Tracks.This field cannot be modified by creators' | translate">
        {{'Tracks.Featured' | translate}}
      </jad-form-fields-checkbox>

      <!-- PRO -->
      <jad-form-fields-checkbox [control]="form.get('isPro')" [required]="false" [disabled]="isCreatorRoute"
        [matTooltipPosition]="'above'" [matTooltipDisabled]="!isCreatorRoute"
        [matTooltip]="'Tracks.This field cannot be modified by creators' | translate">
        {{'Tracks.Pro' | translate}}
      </jad-form-fields-checkbox>

    </div>
  </div>

  <!-- LOADING INDICATOR -->
  <ng-template #loading>
    <div class="flex flex-row justify-center items-center">
      <mat-spinner [diameter]="20"></mat-spinner>
    </div>
  </ng-template>

  <!-- NOTE -->
  <div class="track__note flex flex-row justify-end items-center mb-4 text-base text-secondary-gray-20">
    {{ 'Tracks.*mandatory' | translate }}
  </div>

  <!-- ACTIONS -->
  <div class="track__actions flex flex-row justify-end items-center">
    <!-- CANCEL -->
    <sound-button-default [displayText]="'Tracks.Cancel' | translate" (onClick)="cancelClicked.emit(true)">
    </sound-button-default>
    <!-- SUBMIT -->
    <sound-buttons-submit class="track__actions__submit ml-4" [colorClass]="'purple'" [isLoading]="isLoadingSubmit"
      [isDisabled]="!form.valid || isLoading === true" (onClick)="submit()" [displayText]="'Tracks.Submit' | translate">
    </sound-buttons-submit>
  </div>
</div>
import { BaseModel } from './BaseModel';
import { PlaylistPublicationStatus, PlaylistType } from './Playlist';
import { Track } from './Track';

export class Project extends BaseModel {
    displayName: string;
    description: string;
    playLength: string;
    totalLengthTime: string;

    tracksCount: number;
    userId: string;
    playlistPublicationStatus: string;

    invitedUsers: { roleName: string; id: string; roleId: string }[] = [];

    canEdit: boolean = false;

    canInviteUsers: boolean = false;

    canComment: boolean = false;

    canRate: boolean = false;

    // Relations
    tracks?: Track[];
    type: string = PlaylistType.Project;

    playlists_i18n: ProjectTranslations[] = [];
    translations: ProjectTranslations[] = [];

    isLoading: boolean;

    isIncluded: boolean;

    private _totalLength: number;
    public get totalLength(): number {
        return this._totalLength;
    }
    public set totalLength(value: number) {
        if (value !== this._totalLength) {
            this._totalLength = value;
            this.totalLengthTime = Project.getTotalLength(this.totalLength);
        }
    }

    static getTotalLength(length: number): string {
        const hours = Math.floor(length / 3600);
        const minutes = Math.floor((length - (3600 * hours)) / 60);
        const seconds = length - (60 * minutes) - (3600 * hours);

        return `${this.getPaddedTimeMeasure(hours, true, true)}${this.getPaddedTimeMeasure(minutes, true)}${this.getPaddedTimeMeasure(seconds, false)}`;
    }

    private static getPaddedTimeMeasure(measure: number, withSeparator: boolean = false, shouldHide: boolean = false): string {
        const charCount = measure.toString().length;

        if (measure === 0 && shouldHide) {
            return '';
        }

        switch (charCount) {
            case 0:
                return '';
            case 1:
                return `0${measure}${withSeparator ? ':' : ''}`;
            default:
                return `${measure}${withSeparator ? ':' : ''}`;
        }
    }
}

export class ProjectCreateDto {
    translations: ProjectTranslations[];
    type: string = PlaylistType.Project;
    publicationStatus: string;
}

export class ProjectUpdateDto {
    translations: ProjectTranslations[];
    type: string = PlaylistType.Project;
    publicationStatus: string;
}

export class ProjectTranslations {
    displayName: string;
    description: string;
    languageCode: string;
}

export class CommentInTrackInProject {
    comment: string;
    modified: string;
    modifiedBy: string;
    modifiedByName: string;
}

export class RatingInTrackInProject {
    rating: number;
}

export class CommentInTrackInProjectUpdateOptions {
    comment: string;
}

export class RatingInTrackInProjectUpdateOptions {
    rating: number;
}

export class PublicationStatusUpdateOptions {
    publicationStatus: PlaylistPublicationStatus;
}
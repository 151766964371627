import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { ItemInBasket } from '@app/shared/models/classes/Basket';
import { License, LicenseType } from '@app/shared/models/classes/License';
import { Track } from '@app/shared/models/classes/Track';
import { BasketService } from '@app/shared/services/basket.service';
import { LicensesService } from '@app/shared/services/licenses.service';
import { OrganisationsService } from '@app/shared/services/organisations.service';
import { UsersService } from '@app/shared/services/users.service';
import moment, { Moment } from 'moment';
import { Observable, of, switchMap, tap } from 'rxjs';

export class LicenseSelectorDialogData {
    track: Track;
}

@Component({
    selector: 'sound-license-selector-dialog',
    templateUrl: './license-selector-dialog.component.html',
    styleUrls: ['./license-selector-dialog.component.scss'],
    standalone: false
})
export class LicenseSelectorDialogComponent extends BaseComponent implements OnInit {

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    customLicenses: License[] = [];

    displayUserInfo: boolean = false;

    licenses: License[] = [];

    inADay: Moment = moment().add(7, 'days');

    mailto: string;

    maxLicenseDots = LicensesService.maxLicenseDots;

    standardLicenses: License[] = [];

    withTax: number = 0;

    hideIndividualLicenseBanner: boolean = true;

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private authenticationService: AuthenticationService,
        @Inject(MAT_DIALOG_DATA) public data: LicenseSelectorDialogData,
        private basketService: BasketService,
        public dialogRef: MatDialogRef<LicenseSelectorDialogComponent>,
        private licenseService: LicensesService,
        private organisationsService: OrganisationsService,
        private userService: UsersService,
    ) {
        super();

        this.isLoading = true;

        this.setMailto();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ LIFE CYCLE HOOKS
    // -----------------------------------------------------------------------------------------------------

    ngOnInit() {
        this.initializeLicenseSelectionDialog();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    addToBasketForLicense(license: License): void {

        super.addSubscription(
            this.basketService.addOrUpdateItem(this.data.track, license).subscribe({
                next: (basketItem: ItemInBasket) => {
                    this.dialogRef.close(basketItem);
                }
            })
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE METHODS
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all licenses
     * @returns Return an asynchronous operation containing an array of licenses
     */
    private getLicenses(): Observable<License[]> {
        return this.licenseService
            .getAll('orderby=order', 0, 100, null).pipe(
                tap((licenses: License[]) => {
                    this.licenses = licenses;

                    this.standardLicenses = this.licenses.filter(q => q.type === LicenseType.Standard);

                    this.customLicenses = this.licenses.filter(q => q.type !== LicenseType.Standard);

                    this.licenses = this.customLicenses.concat(this.standardLicenses);

                    this.withTax = this.licenses[0]?.price?.tax ?? 0;

                    this.setLicenseIndexes();

                    this.isLoading = false;
                })
            );
    }

    /**
     * Initialize the license selection dialog
     */
    private initializeLicenseSelectionDialog(): void {
        const user = this.authenticationService.user;
        this.hideIndividualLicenseBanner = this.IsNullOrUndefinedOrEmpty(user?.hideIndividualLicenseBanners) === true ? false : user?.hideIndividualLicenseBanners;

        if (this.IsNullOrUndefinedOrEmpty(user?.organisationId) === false) {
            super.addSubscription(
                this.organisationsService
                    .getOrganisationTrack(this.data.track.id, user.organisationId)
                    .pipe(
                        switchMap((tracks: Track[]) => {
                            if (tracks.length > 0 && user?.disallowMultipleLicensingForTracks === true) {
                                this.displayUserInfo = true;
                                this.isLoading = false;
                                return of(null);
                            }

                            return this.getLicenses()
                        })
                    )
                    .subscribe()
            )
        } else {
            super.addSubscription(this.getLicenses().subscribe());
        }
    }

    private setLicenseIndexes(): void {
        for (const license of this.licenses) {
            license.index = this.licenses.findIndex(q => q.id === license.id);
            license.total = this.licenses.length;
        }
    }

    private setMailto(): void {
        const originalTrackVariation = this.data.track.variations.find(q => q.isOriginal === true);
        const originalTrackVariationName = decodeURIComponent(originalTrackVariation?.displayName);
        const originalTrackVariationArtist = decodeURIComponent(this.data.track.artist.pseudonym);
        const url = decodeURIComponent(`${window.location.protocol}//${window.location.host}/tracks/${this.data.track.id}`);

        let userName = decodeURIComponent('<Please state your name>');
        let userContact = decodeURIComponent('<Please state your email or phone number>');

        if (this.authenticationService.isAuthenticated()) {
            userName = decodeURIComponent(`${this.authenticationService.user.firstName} ${this.authenticationService.user.lastName}`);
            userContact = decodeURIComponent(`${this.authenticationService.user.email}`);
        }

        const subject = decodeURIComponent(`Custom license request (${userName}, ${userContact})`);
        const body = `${decodeURIComponent(`Dear Soundtaxi team`)}%0D%0A%0D%0A${decodeURIComponent(`For the song "${originalTrackVariationName}", by artist "${originalTrackVariationArtist}" (${url}) I require a custom license, which is not listed in your standard license groups.`)}%0D%0A%0D%0A${decodeURIComponent(`My project is about: <Please give a brief description about your project>`)}%0D%0A%0D%0A${decodeURIComponent(`I need the following: <Please give a brief description what you need>`)}%0D%0A%0D%0A${decodeURIComponent(`Thank you and best regards`)}%0D%0A%0D%0A${userName}`;

        this.mailto = `mailto:info@soundtaxi.com?subject=${subject}&body=${body}`;
    }
}

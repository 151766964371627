import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BaseFormComponent } from '@app/shared/base/components/base-form.component';
import { CommentInTrackInProject, CommentInTrackInProjectUpdateOptions } from '@app/shared/models/classes/Project';
import { ProjectsService } from '@app/shared/services/projects.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';

@Component({
    selector: 'sound-track-comment-form',
    templateUrl: './track-comment-form.component.html',
    styleUrls: ['./track-comment-form.component.scss'],
    standalone: false
})
export class TrackCommentFormComponent extends BaseFormComponent<CommentInTrackInProject> implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // ------------------------------------------------------------------------------------------------------

  submitButtonText: string;

  isSuccessful: boolean = false;

  /// -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // ------------------------------------------------------------------------------------------------------

  @Input() projectId: string;

  private _canComment: boolean = false;

  @Input()
  get canComment(): boolean {
    return this._canComment;
  }
  set canComment(value: boolean) {
    this._canComment = value;

    if (value === true) {
      this.form.controls.comment.enable();
    } else {
      this.form.controls.comment.disable();
    }
  }

  @Input() trackId: string;

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // ------------------------------------------------------------------------------------------------------

  constructor(
    private projectsService: ProjectsService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    super();

    this.form = this.fb.group({
      comment: '',
    });
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // ------------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.patchFormValues();
    this.cdr.detectChanges();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------------

  submit(): void {
    this.update();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PROTECTED METHODS
  // ------------------------------------------------------------------------------------------------------

  protected update(): void {
    this.isLoading = true;
    const values = this.form.value;

    const updateOptions = Object.assign(new CommentInTrackInProjectUpdateOptions(), {
      comment: values.comment
    });

    super.addSubscription(
      this.projectsService.updateTrackComment(this.projectId, this.trackId, updateOptions)
        .pipe(
          finalize(() => this.isLoading = false)
        ).subscribe({
          next: (value: CommentInTrackInProject) => {
            this.item = value;
            this.patchFormValues();
          },
        })
    );
  }

  protected create(): void {
    throw new Error('Method not implemented.');
  }

  protected patchFormValues(): void {
    this.form.patchValue({
      comment: this.item.comment
    });
  }

}

import { AfterViewInit, Component, Input, OnDestroy, HostListener } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';
import { PeakData } from '@app/shared/models/classes/PeakData';
import { TrackVariation } from '@app/shared/models/classes/Track';
import { MusicPlayerService } from '@app/shared/services/local/music-player.service';
import { WaveformLoadingService } from '@app/shared/services/local/waveform-loading.service';
import { TrackVariationsService } from '@app/shared/services/trackvariations.service';
import { Observable, Subject, filter, interval, map, switchMap, throttle } from 'rxjs';
import WaveSurfer from 'wavesurfer.js';
import Regions from 'wavesurfer.js/dist/plugins/regions.js';


@Component({
    selector: 'sound-waveform',
    templateUrl: './waveform.component.html',
    styleUrls: ['./waveform.component.scss'],
    standalone: false
})
export class WaveformComponent extends BaseComponent implements AfterViewInit, OnDestroy {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------
  waveSurfer: WaveSurfer;

  peakData: PeakData;

  length: number;

  isLoaded: boolean = false;

  shouldApply: boolean[] = [];

  time: number = 0;

  widthStyle: any = {};

  alreadySeeked: boolean = false;

  ready: boolean = false;

  lastPlayedId: string;

  private _ready$: Subject<boolean> = new Subject<boolean>();

  ready$: Observable<boolean>;

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() width: number = 214;

  @Input() height: number = 80;

  @Input() trackVariation: TrackVariation;

  @Input() fullWidth: boolean = false;

  @Input() id: string = '';

  @Input() showCursor: boolean = false;

  @Input() duration: number = 0;

  @Input() representativeSegmentIndex: number;

  @Input() timestamps: number[];

  private seekOffset: number = 5; // Anzahl der Sekunden zum Spulen

  // Event Listener für Keyboard-Events
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const activeElement = document.activeElement;

      // Überprüfe, ob der Benutzer sich in einem Textfeld oder editierbarem Element befindet
  if (activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement || (activeElement as HTMLElement).isContentEditable) {
    return; // Bricht ab, wenn sich der Fokus in einem Textfeld befindet
  }

    if (!__.IsNullOrUndefined(this.waveSurfer)) {
      const currentTime = this.waveSurfer.getCurrentTime();
      const duration = this.waveSurfer.getDuration();

      if (event.key === 'ArrowUp') {
        // Verhindere das Scrollen der Seite
        event.preventDefault();
        // Spule vor (z. B. 5 Sekunden)
        const newTime = Math.min(currentTime + this.seekOffset, duration);
        this.waveSurfer.seekTo(newTime / duration); // Spulen vorwärts
      }

      if (event.key === 'ArrowDown') {
        // Verhindere das Scrollen der Seite
        event.preventDefault();
        // Spule zurück (z. B. 5 Sekunden)
        const newTime = Math.max(currentTime - this.seekOffset, 0);
        this.waveSurfer.seekTo(newTime / duration); // Spulen rückwärts
      }
    }
  }

  private _isActive: boolean = false;
  @Input()
  public get isActive(): boolean {
    return this._isActive;
  }
  public set isActive(isActive: boolean) {
    if (this._isActive !== isActive) {
      this._isActive = isActive;
      if (this.time !== 0) {
        this.waveSurfer.seekTo(0);
      }
    }
  }

  private _source: string = '';
  @Input()
  public get source(): string {
    return this._source;
  }
  public set source(value: string) {
    if (this._source !== value) {
      this._source = value;

      if (!this.IsNullOrUndefinedOrEmpty(this.waveSurfer)) {
        this.waveSurfer.destroy();
        this.ready = false;
      }
    }
  }

  private _peaksURL: string = '';
  @Input()
  public get peaksURL(): string {
    return this._peaksURL;
  }
  public set peaksURL(peaksURL: string) {
    if (this._peaksURL !== peaksURL) {
      this._peaksURL = peaksURL;

      if (!__.IsNullOrUndefinedOrEmpty(peaksURL)) {
        if (!this.IsNullOrUndefinedOrEmpty(this.waveSurfer)) {
          this.waveSurfer.destroy();
          this.ready = false;
        }
        this.loadPeaks();
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private trackVariationsService: TrackVariationsService,
    private musicPlayerService: MusicPlayerService,
    private waveformLoadingService: WaveformLoadingService
  ) {
    super();

    this.ready$ = new Observable<boolean>(subscriber => {
      if (this.ready === true) {
        subscriber.next(true);
      }

      super.addSubscription(
        this._ready$.subscribe({
          next: (ready: boolean) => {
            subscriber.next(ready);
          }
        })
      )
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngAfterViewInit(): void {

    if (this.fullWidth === false) {
      this.widthStyle = { width: `${this.width}px` };
    }

    if (!this.IsNullOrUndefinedOrEmpty(this.peaksURL)) {
      this.loadPeaks();
    }

    // Handle WaveSurfer Controls: Play, Pause
    super.addSubscription(
      this.musicPlayerService
        .play$
        .pipe(
          filter(q => this.id === '-music-player' || q.id === 'similarity-search'),
          switchMap((data) => {
            return this.ready$.pipe(map(q => data));
          })
        )
        .subscribe({
          next: ({ id, isPlaying }) => {
            if (id === 'similarity-search' && (this.id !== 'similarity-search' || !this.id.endsWith('similarity-search'))) {
              this.waveSurfer.stop();
            }
            if (!this.IsNullOrUndefinedOrEmpty(this.peaksURL) && !this.IsNullOrUndefinedOrEmpty(this.source)) {
              this.togglePlay(id, isPlaying);
            }
          }
        })
    );

    // Handle WaveSurfer Controls: Volume
    super.addSubscription(
      this.musicPlayerService
        .volumeChanges$
        .pipe(
          filter(q => q.id === this.id || this.id === '-music-player')
        )
        .subscribe({
          next: ({ id, volume }) => {
            this.setVolume(volume);
          }
        })
    );

    super.addSubscription(
      this.musicPlayerService
        .progressChanges$
        .pipe(
          filter(q => q.id === this.id || this.id === '-music-player'),
          throttle(() => interval(50))
        )
        .subscribe({
          next: ({ id, progress }) => {
            if ((this.id !== '-music-player') && this.waveSurfer?.getCurrentTime() !== progress) {
              this.handleSeekTo(progress);
            }
          }
        })
    );

    super.addSubscription(
      this.musicPlayerService
        .seekTo$
        .pipe(
          filter(q => this.id === '-music-player')
        )
        .subscribe({
          next: ({ id, seek }) => {
            if ((this.id === '-music-player' || this.isActive === true) && this.waveSurfer.getCurrentTime() !== seek) {
              this.handleSeekTo(seek, 10, true);
            }
          }
        })
    );

    // Handle WaveSurfer Controls: Finish
    super.addSubscription(
      this.musicPlayerService
        .finish$
        .pipe(
          filter(q => q.id === this.id || this.id === '-music-player')
        )
        .subscribe({
          next: ({ id, finished }) => {
            if (this.id === id) {
              this.waveSurfer.stop();
            } else {
              this.musicPlayerService.pause(this.id);
              this.musicPlayerService.seekTo(this.id, 0);
            }
          }
        })
    );

    // Handle WaveSurfer Controls: Track Change
    super.addSubscription(
      this.musicPlayerService
        .trackChanges$
        .subscribe({
          next: ({ id, track }) => {
            this.isActive = id === this.id || this.id === '-music-player';

            if (this.isLoaded === true && this.waveSurfer.isPlaying() === true) {
              this.waveSurfer?.stop();
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    if (!this.IsNullOrUndefined(this.waveSurfer) && !this.waveSurfer.isPlaying) {
      this.waveSurfer.destroy();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------^

  private createWaveSurfer(peaks: PeakData): void {
    if (this.width % 2 !== 0) {
      this.width = this.width + 1;
    }

    const normalize: boolean = String(this.id).includes('music-player') || true;

    const regions = Regions.create();

    this.waveSurfer = WaveSurfer.create({
      container: `#waveform${this.id}`,
      waveColor: '#736D70',
      progressColor: '#FF0088',
      barWidth: 1,
      barRadius: 0,
      barHeight: 0.5, // the height of the wave
      barGap: 1,
      url: this.source,
      peaks: [this.getSparePeaks(peaks)],
      height: this.height,
      normalize,
      plugins: [
        regions
      ]
    });

    // Set volume
    this.setVolume(this.musicPlayerService.volumeLevel);

    // this.waveSurfer.on('interaction', (e: any) => {
    //   if (!this.IsNullOrUndefinedOrEmpty(this.source)) {
    //     this.waveSurfer.setOptions({
    //       progressColor: '#FF0088',
    //       waveColor: '#804062'
    //     })
    //   }
    // });

    // Load song when play is pressed
    this.waveSurfer.on('seeking', this.seeking.bind(this));

    this.waveSurfer.on('play', () => {
      this.musicPlayerService.setIsPlaying(this.id, true);
    });

    // this.waveSurfer.on('pause', () => {
    //   this.musicPlayerService.setIsPlaying(this.id, false);
    // });

    this.waveSurfer.on('click', (relativeX) => {
      // TODO: Does this work for sim search?
      if (this.id !== '-music-player') {
        this.musicPlayerService.seekTo(this.id, relativeX);
      }
    })

    // Tracking position
    this.waveSurfer.on('timeupdate', (time) => {
      if (this.id === '-music-player') {
        this.musicPlayerService.setProgress(`${this.trackVariation.id}undefined`, time);
        this.musicPlayerService.setTime(`${this.trackVariation.id}undefined`, time);
      }
    });

    const hover: any = document.querySelector(`#waveform${this.id} #hover`)
    const waveform = document.querySelector(`#waveform${this.id}`)
    waveform.addEventListener('pointermove', (e: any) => (hover.style.width = `${e.offsetX}px`));

    // Track finish
    this.waveSurfer.on('finish', () => {
      this.musicPlayerService.setHasFinished(this.id, true);
      if (this.id === '-music-player') {
        this.musicPlayerService.setHasFinished(`${this.trackVariation.id}undefined`, true);
      }
    });

    // Draw waveform
    this.waveSurfer.on('ready', () => {
      this.isLoaded = true;
      this.duration = this.waveSurfer.getDuration();
      this.waveformLoadingService.setLoading(false);
      this.ready = true;
      this._ready$.next(true);
      if (!this.IsNullOrUndefinedOrEmpty(this.timestamps) && !this.IsNullOrUndefinedOrEmpty(this.representativeSegmentIndex)) {
        regions.addRegion({
          start: this.timestamps[this.representativeSegmentIndex],
          end: __.IsNullOrUndefinedOrEmpty(this.timestamps[this.representativeSegmentIndex + 1]) ? this.waveSurfer.getDuration() : this.timestamps[this.representativeSegmentIndex + 1],
          color: 'rgba(255, 255, 255, 0.2)',
          drag: false,
          resize: false
        });
        // this.waveSurfer.setTime(this.timestamps[this.representativeSegmentIndex]);
      }
    });

  }

  private seeking(time: any): void {
    // TODO: Determine if the seeking event came from the music player, 
    // or from the currently playing track waveform but not the music player itself.
    // third case: different song
    const newTime = this.waveSurfer.getCurrentTime();

    if (this.isLoaded === true) {
      if (this.id !== '-music-player') {

        this.waveSurfer.un('seeking', null);
        this.handleSeekTo(time);
        this.waveSurfer.on('seeking', this.seeking.bind(this));
        // FIXME: Handle 
        /* this.musicPlayerService.toggleMusicPlayer(this.id, true);
          this.musicPlayerService.setProgress(this.id, time);
          this.musicPlayerService.seekTo(this.id, time);
          */
      } else {
        const a = '';
      }

      // if (this.isActive === false && this.id === '-music-player') {
      //   this.handleSeekTo(time);
      //   this.musicPlayerService.setProgress(this.id, time);
      // } else {
      //   if (newTime !== this.time && this.id !== '-music-player') {
      //     this.time = newTime;
      //     this.musicPlayerService.toggleMusicPlayer(this.id, true);
      //     this.musicPlayerService.setProgress(this.id, time);
      //     this.musicPlayerService.seekTo(this.id, time);
      //   }
      // }
    }
  }

  private togglePlay(id: string, play: boolean): void {
    if (this.id === '-music-player') {
      let playing = false;

      if (__.IsNullOrUndefinedOrEmpty(this.lastPlayedId)) {
        if (play === true) {
          this.waveSurfer.play();
        }
        playing = true;
      } else if (this.lastPlayedId === id && this.waveSurfer.isPlaying() === true) {
        if (play === false) {
          this.waveSurfer.pause();
        }
        playing = false;
      } else if (this.lastPlayedId === id && this.waveSurfer.isPlaying() === false) {
        if (play === true) {
          this.waveSurfer.play();
        }
        playing = true;
      } else if (this.lastPlayedId !== id && this.waveSurfer.isPlaying() === false) {
        if (play === true) {
          this.waveSurfer.play();
        }
        playing = true;
        // this.waveSurfer.pause();
      } else if (play === false) {
        this.waveSurfer.pause();
      }

      this.lastPlayedId = id;


      // if (this.lastPlayedId !== id && this.waveSurfer.isPlaying() === true) {
      //   this.waveSurfer.pause();
      //   this.waveSurfer.play();
      // }

      // if (play === true && this.waveSurfer.isPlaying() === false) {
      //   // Only play in music player
      //   if (this.id === '-music-player') {
      //     this.waveSurfer.play();
      //     this.lastPlayedId = id;
      //   }
      // } else if (play === false && this.waveSurfer.isPlaying() === true) {
      //   this.waveSurfer.pause();
      // }
    }

  }

  private setVolume(volumeLevel: number): void {
    if (!this.IsNullOrUndefinedOrEmpty(this.source)) {
      this.waveSurfer.setVolume(Math.max(0, Math.min(1, volumeLevel / 100)));
    }
  }

  private loadPeaks(): void {
    if (this.isLoading === true) {
      return;
    }
    if (!this.IsNullOrUndefinedOrEmpty(this.source)) {
      this.waveformLoadingService.setLoading(true);
    }

    if (this.IsNullOrUndefinedOrEmpty(this.peaksURL) && !this.IsNullOrUndefinedOrEmpty(this.source)) {
      this.waveformLoadingService.setLoading(true);
      this.loadSource(null);
    }

    this.isLoading = true;

    this.trackVariationsService
      .getPeakDataByURL(this.peaksURL)
      .subscribe({
        next: (peaks: PeakData) => {
          this.isLoading = false;
          this.isLoaded = true;

          if (typeof peaks === 'string') {
            peaks = JSON.parse(peaks);
          }


          this.peakData = peaks;

          this.length = peaks.length / 1000;

          this.createWaveSurfer(peaks);

          this.isLoaded = true;
        },
        error: (err: any) => {
          this.isLoading = false;
          this.isLoaded = false;
          this.waveformLoadingService.setLoading(false);
        }
      });
  }

  private getSparePeaks(peaks: PeakData, roughen: number = 3): number[] {
    return peaks.data;
    // const minPeaks: number = Math.ceil(this.width / roughen);
    // const rate: number = Math.ceil(this.peakData.data.length / minPeaks);
    // return peaks.data.filter((q, index) => index % rate === 0);
  }

  private loadSource(peaks: PeakData): void {
    this.shouldApply = [];
    this.musicPlayerService.pause(this.id);
    this.isLoaded = false;

    const _peaks = [this.getSparePeaks(peaks), this.getSparePeaks(peaks)];

    this.waveSurfer.setOptions({
      url: this.source,
      peaks: _peaks ?? null
    });
    // this.waveSurfer.zoom(1);
    // this.waveSurfer.playPause();
    this.musicPlayerService.play(this.id + `undefined`, false);
  }

  private handleSeekTo(time: number, countdown: number = 10, isRelative: boolean = false): void {
    if (countdown >= 0) {
      if (this.musicPlayerService.isExpanded) {
        if (this.alreadySeeked === false) {
          this.waveSurfer.seekTo(isRelative === false ? time / this.duration : time);
          this.alreadySeeked = true;
          setTimeout(() => {
            this.alreadySeeked = false;
          }, 50);
        }
      } else {
        setTimeout(() => this.handleSeekTo(time, countdown - 1), 100);
      }
    }
  }
}

import { Component, Input } from '@angular/core';
import { TrackStatus } from '@app/shared/models/enums/TrackStatus';

@Component({
    selector: 'sound-track-status',
    templateUrl: './track-status.component.html',
    styleUrls: ['./track-status.component.scss'],
    standalone: false
})
export class TrackStatusComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  TrackStatus = TrackStatus;

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() trackStatus: string;
}

<div class="track-status flex flex-row items-center">

  <!-- INDICATOR -->
  <div class="track-status__indicator mr-2" [ngClass]="{'track-status__indicator--online': TrackStatus.Online === trackStatus,
  'track-status__indicator--offline': TrackStatus.Offline === trackStatus,
    'track-status__indicator--submitted': TrackStatus.Submitted === trackStatus,
    'track-status__indicator--pending': TrackStatus.Pending === trackStatus,
   'track-status__indicator--uploaded': TrackStatus.Uploaded === trackStatus}"></div>

  <!-- STATUS -->
  <div class="track-status_displayName">
    {{ trackStatus }}
  </div>

</div>
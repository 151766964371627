<!-- ACTIONS -->
<ng-container *ngIf="isCreatorRoute === false && isCuratorRoute === false">
    <td class="mobile-hidden"
        [ngClass]="{'tracks-item__actions tracks-item__cell': true, 'tracks-item__actions--active': showVariations?.value === true, 'tracks-item__cell--loading': isLoading}">
        <div *loading="isLoading; class: 'tracks-item--loading'">
            <div class="flex flex-row items-center">
                <!-- FAVORITE -->
                <mat-icon [svgIcon]="'favorite'"
                    [ngClass]="{'tracks-item__action tracks-item__action--like cursor-pointer large': true, 'active': isFavorite()}"
                    (click)="toggleFavorites()">
                </mat-icon>

                <!--ADD TO PROJECT -->
                <ng-container *ngIf="isMyProjectsRoute === false; else removeFromProject;">
                    <mat-icon class="tracks-item__action tracks-item__action--project large cursor-pointer"
                        [svgIcon]="'plus_square'" 
                        [matTooltipPosition]="'above'"
                        [matTooltip]="'Tracks.Add To Project' | translate" 
                        (click)="openAddToProjectDialog()">
                    </mat-icon>
                </ng-container>

                <!-- REMOVE FROM PROJECT -->
                <ng-template #removeFromProject>
                    <mat-icon class="tracks-item__action tracks-item__action--project large cursor-pointer"
                        [svgIcon]="'minus_square'" 
                        [matTooltipPosition]="'above'"
                        [matTooltip]="'Tracks.Remove From Project' | translate" 
                        (click)="openRemoveFromProjectDialog()">
                    </mat-icon>
                </ng-template>
            </div>
        </div>
    </td>
</ng-container>

<!-- IMAGE -->
<td
    [ngClass]="{'tracks-item__image tracks-item__cell relative': true, 'tracks-item__cell--loading-no-padding': isLoading}">
    <div *loading="isLoading; class: 'tracks-item--loading'">
        <!-- PLAY -->
        <div class="tracks-item__play" [ngClass]="{'tracks-item__play--active': isActive === true}">
            <mat-icon [svgIcon]="isActive === true && isPlaying?.isPlaying === true ? 'pause' : 'play'"
                class="play-variation" (click)="togglePlay()"></mat-icon>
        </div>
        <sound-image [src]="image" [height]="'5.714rem'" [width]="'5.714rem'"></sound-image>
    </div>
</td>

<!-- TITLE  -->
<td class="truncate"
    [ngClass]="{'tracks-item__title tracks-item__cell': true, 'tracks-item__cell--loading': isLoading}">
    <div *loading="isLoading; class: 'tracks-item--loading'">
        <div class="tracks-item__markers">
            <sound-badge [type]="BadgeType.New" *ngIf="item?.isNew"></sound-badge>
            <sound-badge [type]="BadgeType.Pro"
                [text]="track.isPro ? track?.artist?.pro?.displayName : ('Browse.Non-Pro' | translate)">
            </sound-badge>
            <div *ngIf="!IsNullOrUndefined(projectId)">
                <sound-track-rating-form [projectId]="projectId" [trackId]="track?.id" [canRate]="canRate"
                    [rating]="track?.rating"></sound-track-rating-form>
            </div>
        </div>
        <a [soundtaxiRouterLink]="'/tracks/' + originalVariation?.normalizedName"
            class="tracks-item__name mt-[2px] block" [ngClass]="{'tracks-item__name--active': isActive === true}"
            [matTooltipPosition]="'above'" [matTooltip]="item?.displayName"
            [matTooltipDisabled]="isDisabledTooltip(tracksItemName)" #tracksItemName>
            {{ item?.displayName }}
        </a>

        <a [soundtaxiRouterLink]="'/artists/' + track.artist?.normalizedPseudonym" class="tracks-item__subtitle block">
            {{ track?.artist?.pseudonym }}
        </a>
    </div>
</td>

<!-- VARIATIONS COUNT -->
<td class=""
    [ngClass]="{'tracks-item__variations-container tracks-item__cell': true, 'tracks-item__cell--active': isActive === true, 'tracks-item__variations-container--loading': isLoading}">
    <div *loading="isLoading; class: 'tracks-item--loading'">
        <div *ngIf="variationsCount > 0"
            [ngClass]="{'tracks-item__variations': true, 'tracks-item__variations--index': isMainItem === false}"
            (click)="toggleVariations()" [matTooltipPosition]="'above'"
            [matTooltip]="isMainItem ? ('Tracks.More Versions' | translate) : ''"
            [matTooltipDisabled]="variationsCount === 0 || !isMainItem">
            {{ variationsCount }}
        </div>
    </div>
</td>


<!-- BPM -->
<ng-container *ngIf="isCreatorRoute === false && isCuratorRoute === false && viewWidth > 1115">
    <td
        [ngClass]="{'tracks-item__bpm tracks-item__cell': true, 'tracks-item__cell--active': isActive === true, 'tracks-item__cell--loading': isLoading}">
        <div *loading="isLoading; class: 'tracks-item--loading'">
            {{ item?.bpm }}
        </div>
    </td>
</ng-container>

<!-- GENRE -->
<ng-container *ngIf="isCreatorRoute === false && isCuratorRoute === false && viewWidth > 862 ">
    <td
        [ngClass]="{'tracks-item__genre tracks-item__cell': true, 'tracks-item__cell--active': isActive === true, 'tracks-item__cell--loading': isLoading}">
        <div *ngIf="item?.subGenres[0]?.displayName; else defaultGenreRow">
            <div *loading="isLoading; class: 'tracks-item--loading'">
                {{ item?.subGenres[0]?.displayName }}
            </div>
        </div>
        <ng-template #defaultGenreRow>
            <div *loading="isLoading; class: 'tracks-item--loading'">
                {{ item?.genres[0]?.displayName }}
            </div>
        </ng-template>
    </td>
</ng-container>

<!-- WAVEFORM -->
<ng-container *ngIf="viewWidth > 580">
    <td [ngClass]="{'tracks-item__waveform tracks-item__cell': true, 'tracks-item__cell--loading': isLoading}">
        <div *loading="isLoading; class: 'tracks-item--loading'">
            <sound-waveform [id]="item?.id + trackListId" [style.display]="'table-cell'" [height]="43"
                [duration]="item?.length" [timestamps]="track?.timestamps"
                [representativeSegmentIndex]="track?.representativeSegmentIndex" [peaksURL]="getPeaksURL()"
                [isActive]="isActive" [source]="getSource()" (click)="isActive ? null : togglePlay()">
            </sound-waveform>
        </div>
    </td>
</ng-container>

<!-- STATUS -->
<ng-container *ngIf="isCreatorRoute === true || isCuratorRoute === true">
    <td
        [ngClass]="{'tracks-item__status tracks-item__cell': true, 'tracks-item__cell--active': isActive === true, 'tracks-item__cell--loading': isLoading}">
        <div *loading="isLoading; class: 'tracks-item--loading'">
            <sound-track-status [trackStatus]="item?.status"></sound-track-status>
        </div>
    </td>
</ng-container>

<!-- PUBLISHING DATE -->
<ng-container *ngIf="isCreatorRoute === true || isCuratorRoute === true">
    <td
        [ngClass]="{'tracks-item__created tracks-item__cell': true, 'tracks-item__cell--active': isActive === true, 'tracks-item__cell--loading': isLoading}">
        <div *loading="isLoading; class: 'tracks-item--loading'">
            {{ item?.publishingDate | date: 'dd.MM.yyyy' }}
        </div>
    </td>
</ng-container>

<!-- LENGTH -->

<ng-container *ngIf="viewWidth > 1040">
    <td
        [ngClass]="{'tracks-item__length tracks-item__cell': true, 'tracks-item__cell--active': isActive === true, 'tracks-item__cell--loading': isLoading}">
        <div *loading="isLoading; class: 'tracks-item--loading'">
            {{ item?.lengthTime }}
        </div>
    </td>
</ng-container>

<!-- ENERGY LEVELS -->
<ng-container *ngIf="isCreatorRoute === false && isCuratorRoute === false && viewWidth > 1211">
    <td
        [ngClass]="{'tracks-item__energy-levels tracks-item__cell': true, 'tracks-item__cell--active': isActive === true, 'tracks-item__cell--loading': isLoading}">
        <div *loading="isLoading; class: 'tracks-item--loading'">
            <sound-energy-levels [energyLevel]="item?.energyLevel"></sound-energy-levels>
        </div>
    </td>
</ng-container>

<!-- ACTIONS -->
<td
    [ngClass]="{'tracks-item__actions tracks-item__cell': true, 'tracks-item__actions--small': isCuratorRoute === true || isCreatorRoute === true, 'tracks-item__cell--active': isActive === true, 'tracks-item__cell--loading': isLoading}">
    <div *loading="isLoading; class: 'tracks-item--loading'">
        <ng-container *ngIf="isCreatorRoute === false && isCuratorRoute === false; else creatorCuratorActions;">
            <div class="flex flex-row items-center justify-end">

                <ng-container *ngIf="viewWidth < 692; else displayIcons">
                    <sound-buttons-menu [sizeClass]="'medium'" [colorClass]="'transparent'">
                        <ng-template soundButtonsMenuLabel>
                            <mat-icon [ngClass]="['large']" [svgIcon]="'ellipsis'"></mat-icon>
                        </ng-template>
                        <sound-buttons-menu-action *ngIf="isDeletable === true" (onClick)="deleteClicked.emit()"
                            [order]="'icon-last'" [iconClass]="'menu'" [displayText]="'Tracks.Delete' | translate"
                            colorClass="default" sizeClass="large" icon="trash">
                        </sound-buttons-menu-action>

                        <sound-buttons-menu-action (onClick)="openSimilaritySearchDialog()" [order]="'icon-last'"
                            [iconClass]="'menu'" [displayText]="'Tracks.Similarity search' | translate"
                            colorClass="default" sizeClass="large" icon="similarity">
                        </sound-buttons-menu-action>

                        <sound-buttons-menu-action (onClick)="element?.trigger?.openMenu()" [order]="'icon-last'"
                            [iconClass]="'menu'" [displayText]="'Tracks.Download' | translate" colorClass="default"
                            sizeClass="large" icon="download">
                        </sound-buttons-menu-action>

                        <sound-buttons-menu-action *ngIf="isMainItem" (onClick)="openLicenseSelectorDialog()"
                            [order]="'icon-last'" [iconClass]="'menu'"
                            [displayText]="'Tracks.Add to basket' | translate" colorClass="default" sizeClass="large"
                            icon="shopping_cart_plus">
                        </sound-buttons-menu-action>

                        <sound-buttons-menu-action *ngIf="isMainItem" (onClick)="toggleFavorites()"
                            [order]="'icon-last'" [iconClass]="'menu'"
                            [displayText]="'MusicPlayer.Add To Favourites' | translate" colorClass="default"
                            [sizeClass]="'mobil-visible large' + (isFavorite() ? ' active' : '')" icon="favorite">
                        </sound-buttons-menu-action>

                        <!-- Hide in v1
                            <sound-buttons-menu-action *ngIf="isMainItem" (onClick)="openAddToProjectDialog()"
                            [order]="'icon-last'" [iconClass]="'menu'"
                            [displayText]="'MusicPlayer.Add To Project' | translate" colorClass="default"
                            sizeClass="mobil-visible large" icon="plus_square">
                        </sound-buttons-menu-action> -->

                    </sound-buttons-menu>

                    <sound-download-button class="tracks-item__action tracks-item__action--hidden cursor-pointer"
                        (downloadClicked)="downloadTrackVariationByResolution($event)"></sound-download-button>
                </ng-container>
                <ng-template #displayIcons>
                    <!-- SHARE -->
                    <!-- <sound-share-button></sound-share-button> -->

                    <!-- DELETE -->
                    <mat-icon [svgIcon]="'trash'" class="tracks-item__action cursor-pointer large"
                        *ngIf="isDeletable === true" (click)="deleteClicked.emit()">
                    </mat-icon>

                    <!-- SIMILARITY SEARCH -->
                    <mat-icon class="tracks-item__action cursor-pointer large" [matTooltipPosition]="'above'"
                        [matTooltip]="'Tracks.Similarity search' | translate" [svgIcon]="'similarity'"
                        (click)="openSimilaritySearchDialog()">
                    </mat-icon>

                    <!-- DOWNLOAD -->
                    <sound-download-button class="tracks-item__action cursor-pointer" [matTooltipPosition]="'above'"
                    [matTooltip]="'Tracks.Download' | translate"
                    (downloadClicked)="downloadTrackVariationByResolution($event)"></sound-download-button>

                    <!-- SHOPPING CART -->
                    <mat-icon *ngIf="isMainItem" class="tracks-item__action cursor-pointer large" [matTooltipPosition]="'above'"
                        [matTooltip]="'Tracks.Select license' | translate"
                        [svgIcon]="'shopping_cart_plus'" (click)="openLicenseSelectorDialog()">
                    </mat-icon>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #creatorCuratorActions>
            <div class="flex flex-row items-center justify-end">
                <ng-container *ngIf="trackListId !== 'creator-my-songs'">
                    <!-- DELETE -->
                    <mat-icon [svgIcon]="'trash'"
                        [ngClass]="{'tracks-item__action cursor-pointer large': true, 'disabled cursor-default': isCreatorRoute === true && (item?.status === TrackVariationStatus.Submitted || item?.status === TrackVariationStatus.Online || item?.status === TrackVariationStatus.Offline) }"
                        [matTooltipPosition]="'above'" [matTooltip]="'Tracks.Delete' | translate"
                        (click)="isCreatorRoute === true && (item?.status === TrackVariationStatus.Submitted || item?.status === TrackVariationStatus.Online || item?.status === TrackVariationStatus.Offline) ? null : openDeleteDialog()">
                    </mat-icon>

                    <!-- EDIT -->
                    <mat-icon
                        [ngClass]="{'tracks-item__action cursor-pointer large': true, 'disabled cursor-default': isCreatorRoute === true && item?.status !== TrackVariationStatus.Uploaded && item?.status !== TrackVariationStatus.Pending}"
                        [matTooltipPosition]="'above'" [matTooltip]="'Tracks.Edit' | translate" [svgIcon]="'edit'"
                        (click)="isCreatorRoute === true && item?.status !== TrackVariationStatus.Uploaded && item?.status !== TrackVariationStatus.Pending ? null : editTrack()">
                    </mat-icon>

                    <!-- PUBLISH FOR UPLOADS LIST -->
                    <ng-container *ngIf="trackListId !== 'curator-my-songs' && isCuratorRoute === true">
                        <!-- PUBLISH -->
                        <mat-icon
                            [ngClass]="{'tracks-item__action cursor-pointer large': true, 'disabled cursor-default': item?.status !== TrackVariationStatus.Submitted}"
                            [matTooltipPosition]="'above'" [matTooltip]="'Tracks.Publish' | translate"
                            [svgIcon]="'publish'"
                            (click)="item?.status !== TrackVariationStatus.Submitted ? null : publishTrack()">
                        </mat-icon>
                    </ng-container>

                    <!-- PUBLISH FOR MY SONGS LIST -->
                    <ng-container *ngIf="trackListId === 'curator-my-songs' && isCuratorRoute === true">
                        <!-- PUBLISH -->
                        <mat-icon
                            [ngClass]="{'tracks-item__action cursor-pointer large': true, 'disabled cursor-default': item?.status === TrackVariationStatus.Online}"
                            [matTooltipPosition]="'above'" [matTooltip]="'Tracks.Publish' | translate"
                            [svgIcon]="'publish'"
                            (click)="item?.status !== TrackVariationStatus.Online ? publishTrack() : null">
                        </mat-icon>
                    </ng-container>

                    <!-- SUBMIT -->
                    <mat-icon
                        [ngClass]="{'tracks-item__action cursor-pointer large': true, 'disabled cursor-default': item?.status !== TrackVariationStatus.Uploaded}"
                        [matTooltipPosition]="'above'" [matTooltip]="'Tracks.Submit Song' | translate"
                        [svgIcon]="'submit'"
                        (click)="item?.status !== TrackVariationStatus.Uploaded ? null : submitTrack()"
                        *ngIf="trackListId !== 'curator-my-songs'">
                    </mat-icon>
                </ng-container>
            </div>
        </ng-template>
    </div>
</td>

<!-- SELECT -->
<td [ngClass]="{'tracks-item__select tracks-item__cell': true, 'tracks-item__cell--loading': isLoading}"
    *ngIf="isCuratorRoute === true || selectable === true">
    <div *loading="isLoading; class: 'tracks-item--loading'">
        <jad-form-fields-checkbox [control]="isSelected">
        </jad-form-fields-checkbox>
    </div>
</td>
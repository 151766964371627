<sound-dialog-header style="flex: 0 1 auto;" [title]="'Basket.My Cart' | translate"
    (emitCloseClick)="dialogRef.close(null)" class="basket__header" [icon]="'shopping_cart'">
    <!-- <mat-icon class="medium" [svgIcon]="'share'" (click)="shareCart()" class="share-icon"></mat-icon> -->
</sound-dialog-header>
<sound-dialog-content class="basket__content" style="flex: 1 1 auto; margin-top: 24px;">
    <div class="basket-item__container" *ngFor="let item of basketItemGroups">
        <div class="track__container">
            <div class="track__image">
                <sound-image [src]="item?.image" [height]="'5.471rem'"></sound-image>
            </div>
            <div class="track__title">
                <div class="track__markers">
                    <sound-badge [type]="BadgeType.New" *ngIf="item?.track?.variations[0].isNew"></sound-badge>
                    <sound-badge [type]="BadgeType.Pro"
                        [text]="item?.track?.isPro ? item?.track?.artist?.pro?.displayName : ('Browse.Non-Pro' | translate)">
                    </sound-badge>
                </div>
                <div class="track__name" [matTooltipPosition]="'above'"
                    [matTooltip]="item?.track?.variations[0]?.displayName">
                    <a [soundtaxiRouterLink]="'/tracks/' + getOriginalVariation(item?.track)?.normalizedName">
                        {{ item?.track?.variations[0].displayName }}
                    </a>
                </div>
                <div class="track__subtitle">
                    <a [soundtaxiRouterLink]="'/artists/' + item?.track?.artist?.normalizedPseudonym">
                        {{ item?.track?.artist?.pseudonym }}
                    </a>
                </div>
            </div>
        </div>
        <div class="licenses__container">
            <div class="license__container" *ngFor="let licenseQuantity of item.items">
                <div class="license__title">
                    {{ licenseQuantity?.license?.displayName }}
                </div>
                <div class="license__details">
                    <div class="license__counter">
                        <mat-icon class="medium" [svgIcon]="'minus_square'"
                            (click)="updateQuantity(item, licenseQuantity?.license?.id, licenseQuantity.quantity - 1)">
                        </mat-icon>
                        <div class="license__quantity">
                            {{ licenseQuantity?.quantity }}
                        </div>
                        <mat-icon class="medium" [svgIcon]="'plus_square'"
                            (click)="updateQuantity(item, licenseQuantity?.license?.id, licenseQuantity.quantity + 1)">
                        </mat-icon>
                    </div>
                    <div class="license__price"
                        *ngIf="licenseQuantity?.license?.type !== 'Flatrate' && licenseQuantity?.license?.type !== 'Contingent'">
                        {{ licenseQuantity?.license?.price?.amount / 100 |
                        defaultCurrency:licenseQuantity?.license?.price?.currency }}
                    </div>
                    <div class="license__actions">
                        <mat-icon class="small" [svgIcon]="'trash'" (click)="shareCart()"
                            (click)="deleteLicense(item, licenseQuantity?.license?.id)"></mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</sound-dialog-content>
<sound-dialog-footer class="basket__footer" style="flex: 0 1 auto;">
    <div [ngClass]="{'basket__prices': true, 'basket__prices--discount': discounted != 0 }"
        *ngIf="hasAnyCustomLicense === false">
        <ng-container *ngIf="discounted != 0; else normalTotal">
            <div class="basket__price">
                <h3> {{ 'Basket.Sub-Total' | translate }} </h3>
                <h3>
                    {{ total / 100 | defaultCurrency: basketItemGroups[0]?.items[0]?.license?.price?.currency }}
                </h3>
            </div>
            <div class="basket__price">
                <div class="text-base text-sm font-light text-end"> {{ 'Basket.Discount sum' | translate: {discount:
                    percentOff} }}
                </div>
                <div class="text-base text-sm font-light text-end">
                    {{ (-1 * discounted) / 100 | defaultCurrency:
                    basketItemGroups[0]?.items[0]?.license?.price?.currency }}
                </div>
            </div>
            <div class="basket__price basket__price--total">
                <h3> {{ 'Basket.Total' | translate }} </h3>
                <h3>
                    {{ (total - discounted) / 100 | defaultCurrency:
                    basketItemGroups[0]?.items[0]?.license?.price?.currency }}
                </h3>
            </div>
            <div *ngIf="withTax != 0 && showTax === true" class="basket__price basket__price--tax">
                {{ 'Basket.Mwst' | translate: {tax: withTax} }}
            </div>
        </ng-container>
        <ng-template #normalTotal>
            <div class="basket__price basket__price--total">
                <h3> {{ 'Basket.Total' | translate }} </h3>
                <h3>
                    {{ total / 100 | defaultCurrency: basketItemGroups[0]?.items[0]?.license?.price?.currency }}
                </h3>
            </div>
            <div *ngIf="withTax != 0 && showTax === true" class="basket__price basket__price--tax">
                {{ 'Basket.Mwst' | translate: {tax: withTax} }}
            </div>
        </ng-template>
    </div>
    <div class="basket__actions">
        <sound-button-default [colorClass]="'default'" [displayText]="'Basket.Continue Shopping' | translate"
            (onClick)="dialogRef.close(null)"></sound-button-default>
        <sound-button-default [colorClass]="'green'" [displayText]="'Basket.Checkout' | translate" [icon]="'submit'"
            [sizeClass]="'large'" (onClick)="dialogRef.close(true)"></sound-button-default>
    </div>
</sound-dialog-footer>
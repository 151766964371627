<sound-tracks-variation [viewWidth]="viewWidth" [showVariations]="showVariations" [track]="item" [isMainItem]="true"
    [trackListId]="trackListId" [shouldLoadWaveform]="shouldLoadWaveform"
    [variationsCount]="item?.variations?.length - 1" [isLoading]="isLoading" [shouldResetPlaylist]="shouldResetPlaylist"
    [class]="'tracks-row__entry'" (deletedTrack)="deletedTrack.emit(true)" (publishedTrack)="publishedTrack.emit(true)"
    (submittedTrack)="submittedTrack.emit(true)" (editClicked)="showEditForm = !showEditForm"
    [canRate]="permissions?.canRate" [isDeletable]="isDeletable" [selectable]="selectable"
    [shouldAddAllTracksToPlaylist]="shouldAddAllTracksToPlaylist" (removedFromPlaylist)="removedFromPlaylist.emit(true)"
    (deleteClicked)="deleteClicked.emit(item)" (addAllTracksToPlaylist)="addAllTracksToPlaylist.emit($event)"
    (toggledFavorite)="toggledFavorite.emit(true)">
</sound-tracks-variation>

<!-- EDIT FORM -->
<ng-container *ngIf="showEditForm === true">
    <tr
        [ngClass]="{'tracks-row__edit__container': true, 'tracks-row__edit__container--original': true, 'tracks-row__edit__container--active': showEditForm === true }">
        <td [attr.colspan]="9">
            <sound-tracks-form [trackVariationId]="getOriginalTrackVariationId()"
                [itemId]="originalVariation?.normalizedName" (cancelClicked)="showEditForm = !showEditForm"
                (saved)="saveTrackVariation()"></sound-tracks-form>
        </td>
    </tr>
</ng-container>

<!-- DESCRIPTION -->
<tr [ngClass]="{'tracks-row__description__container': true, 'tracks-row__description__container--active': showVariations.value === true}"
    *ngIf="showInfo === true && showEditForm === false">
    <td class="hidden sm:block" [attr.colspan]="isCuratorRoute || isCreatorRoute ? 1 : 2"></td>
    <td class="tracks-row__description" [attr.colspan]="descriptionColSpan">
        <!-- GET DESCRIPTION BY LANGUAGE -->
        {{ originalVariation?.description }}
        <div *ngIf="!IsNullOrUndefined(projectId)" class="mt-5">
            <sound-track-comment-form [projectId]="projectId" [trackId]="item?.id" [item]="item"
                [canComment]="permissions?.canComment"></sound-track-comment-form>
        </div>
    </td>
    <td class="hidden sm:block" [attr.colspan]="isCreatorRoute ? 1 : 2"></td>
</tr>

<!-- VARIATIONS -->
<ng-container *ngIf="showVariations.value === true">
    <ng-container *ngFor="let variation of item?.variations; let index = index">
        <ng-container *ngIf="index !== 0">
            <sound-tracks-variation [viewWidth]="viewWidth" [track]="item" [shouldLoadWaveform]="shouldLoadWaveform"
                [shouldAddAllTracksToPlaylist]="shouldAddAllTracksToPlaylist"
                [shouldResetPlaylist]="shouldResetPlaylist" (deletedTrack)="deletedTrack.emit(true)"
                (publishedTrack)="publishedTrack.emit(true)" (submittedTrack)="submittedTrack.emit(true)"
                [class]="'tracks-row__entry--child'" [showVariations]="showVariations" [trackListId]="trackListId"
                (editClicked)="toggleEditFormByItem(variation)" [variationsCount]="index"
                (addAllTracksToPlaylist)="addAllTracksToPlaylist.emit($event)">
            </sound-tracks-variation>

            <!-- EDIT FORM -->
            <ng-container *ngIf="getShowEditFormByItem(variation)">
                <tr
                    [ngClass]="{'tracks-row__edit__container': true, 'tracks-row__edit__container--active': getShowEditFormByItem(variation)}">
                    <td [attr.colspan]="9">
                        <sound-tracks-form [trackVariationId]="variation.id"
                            [itemId]="originalVariation?.normalizedName"
                            (cancelClicked)="toggleEditFormByItem(variation)">
                        </sound-tracks-form>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
<tr class="empty-row"></tr>
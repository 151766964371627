import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from '@app/shared';
import { DialogModule } from '@app/shared/components/dialog/dialog.module';
import { LoadingBarModule } from '@app/shared/services/local/loading-bar/loading-bar.module';
import { TracksService } from '@app/shared/services/tracks.service';
import { TranslateModule } from '@ngx-translate/core';
import { SimilaritySearchDialogComponent } from './similarity-search-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    MatPaginatorModule,
    MatIconModule,
    DialogModule,
    MatDialogModule,
    LoadingBarModule
  ],
  declarations: [SimilaritySearchDialogComponent],
  exports: [SimilaritySearchDialogComponent],
  providers: [TracksService]
})
export class SimilaritySearchDialogModule { }

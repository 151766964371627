import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import { I18nService } from '@app/core/i18n.service';
import { map, Observable } from 'rxjs';

import { Task } from '../models/classes/Task';
import { WebhookTask } from '../models/classes/WebhookTask';
import { EntityService } from './entity.service';
import { Response } from './local/Response';

@Injectable()
export class TasksService extends EntityService<WebhookTask, WebhookTask, WebhookTask> {

    /// -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(protected httpService: HttpService, protected i18nService: I18nService) {
        super('tasks', 'task', httpService, i18nService);
    }

    getTasks(taskIds: string[]): Observable<WebhookTask[]> {
        return this.httpService.post<Response<WebhookTask[]>>(
            `tasks/tasks`, Object.assign(new Task, {
                taskIds: taskIds
            })
        ).pipe(
            map((response: Response<WebhookTask[]>) => {
                return response.data;
            })
        )
    }
}
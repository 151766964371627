import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/base/services';
import { FileContainer } from '@app/shared/components/files/models/FileContainer';
import { __ } from '@app/shared/functions/object.functions';
import { SpotifyTrack } from '@app/shared/models/classes/SpotifyTrack';

const SIMILARITY_SEARCH_KEY = '34asdaaisadisdweqwe54dfisa3i0';

@Injectable()
export class SimilaritySearchService extends BaseService {

  /// -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // ------------------------------------------------------------------------------------------------------

  private _similaritySearchedItems: { mp3: { id: string, source: string }, spotifyTrack: SpotifyTrack };

  private _spotifyTrack: SpotifyTrack;

  set spotifyTrack(spotifyTrack: SpotifyTrack) {
    if (!__.IsNullOrUndefined(spotifyTrack)) {
      this._spotifyTrack = spotifyTrack;
      this._similaritySearchedItems.spotifyTrack = spotifyTrack;
      localStorage.setItem(SIMILARITY_SEARCH_KEY, JSON.stringify(this._similaritySearchedItems));
    }
  }
  public get spotifyTrack(): SpotifyTrack {
    return this._spotifyTrack;
  }

  private _cyaniteId: string;

  set cyaniteId(cyaniteId: string) {
      this._cyaniteId = cyaniteId;
  }
  public get cyaniteId(): string {
    return this._cyaniteId;
  }

  private _mp3: FileContainer;

  set mp3(mp3: FileContainer) {
    if (!__.IsNullOrUndefined(mp3)) {
      this._mp3 = mp3;
      try {
        this._similaritySearchedItems.mp3 = { id: this._cyaniteId, source: null };
      } catch (error) {
        console.error(error);
      }
    }
  }
  public get mp3(): FileContainer {
    return this._mp3;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor() {
    super();
      this._similaritySearchedItems = { mp3: { id: '', source: '' }, spotifyTrack: null };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  clearCyanityId(){
    this._cyaniteId = null;
  }

}
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';
import { RatingInTrackInProject, RatingInTrackInProjectUpdateOptions } from '@app/shared/models/classes/Project';
import { ProjectsService } from '@app/shared/services/projects.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';

@Component({
    selector: 'sound-track-rating-form',
    templateUrl: './track-rating-form.component.html',
    styleUrls: ['./track-rating-form.component.scss'],
    standalone: false
})
export class TrackRatingFormComponent extends BaseComponent implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // ------------------------------------------------------------------------------------------------------

  Array = Array;

  total: number[] = [1, 2, 3, 4, 5];

  /// -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // ------------------------------------------------------------------------------------------------------

  @Input() projectId: string;

  @Input() trackId: string;

  @Input() canRate: boolean = false;

  @Input() rating: number = null;

  /// -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // ------------------------------------------------------------------------------------------------------

  constructor(
    private projectsService: ProjectsService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService
  ) {
    super();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // ------------------------------------------------------------------------------------------------------

  ngOnInit(): void {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  setRating(index: number): void {
    if (this.canRate === true) {
      const updateOptions = Object.assign(new RatingInTrackInProjectUpdateOptions(), {
        rating: index == this.rating ? null : index
      });

      this.projectsService.updateTrackRating(this.projectId, this.trackId, updateOptions)
        .pipe(
          finalize(() => this.isLoading = false)
        ).subscribe({
          next: (value: RatingInTrackInProject) => {
            this.rating = __.IsNullOrUndefinedOrEmpty(value.rating) ? null : value.rating;
          }
        });
    }
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ PROTECTED METHODS
  // ------------------------------------------------------------------------------------------------------
}

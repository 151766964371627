<sound-dialog-header [title]="'License.Select a license' | translate" (emitCloseClick)="dialogRef.close(null)"
    class="dialog-header" [icon]="'shopping_cart'"></sound-dialog-header>

<sound-dialog-content>
    <div *ngIf="isLoading === false && displayUserInfo === false">
        <ng-container *ngFor="let license of licenses; let index = index">
            <sound-licenses-selector-detail [license]="license" [currentIndex]="license.level" [total]="maxLicenseDots"
                [withTax]="withTax" (addLicenseClicked)="addToBasketForLicense(license)">
            </sound-licenses-selector-detail>
        </ng-container>
        <div class="custom-license__container flex-col sm:flex-row" *ngIf="!hideIndividualLicenseBanner">
            <div class="custom-license__description">
                <div class="custom-license__header">
                    <h3> {{ 'License.Custom License' | translate }}</h3>
                </div>
                <p>
                    {{ 'License.Custom License description' | translate }}
                </p>
            </div>

            <div class="custom-license__actions mt-4 sm:mt-0">
                <a [href]="mailto">
                    <sound-button-default [colorClass]="'default'" [buttonStyle]="'white-space: nowrap;'"
                        [icon]="'soundtaxi_logo'" [displayText]="'License.License request' | translate">
                    </sound-button-default>
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="displayUserInfo">
        <div class="flex flex-grow items-center">
            <mat-icon fontIcon="warning" style="font-size: 20px; height: 20px; width: 20px; margin-right: 10px; color: rgb(161 98 7 / 1)" ></mat-icon><div>{{ 'Licenses.Track already licensed' | translate }}</div>
        </div>
    </div>
    <ng-container *ngIf="isLoading === true">
        <div class="flex justify-center items-center">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
    </ng-container>
</sound-dialog-content>
import { Injectable } from '@angular/core';
import { __ } from '@app/shared/functions/object.functions';
import { SubSubNavigationView } from '@app/shared/models/enums/SubSubNavigationView.enum';
import { BehaviorSubject, Observable, Subject, debounceTime, filter, fromEvent } from 'rxjs';
import { BaseService } from '../../base/services';
import { NavigationStart, Router } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';


@Injectable()
export class SidebarService extends BaseService {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  mediaQuery: number = 768;

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private set _sidebar(expanded: boolean) {
    localStorage.setItem("sideBar", String(expanded));
  }

  private get _sidebar() {
    if (window.outerWidth <= this.mediaQuery) {
      return false;
    }

    return JSON.parse(localStorage.getItem('sideBar')) == null ? true : JSON.parse(localStorage.getItem('sideBar'));
  }

  private get _browseBar() {
    if (window.outerWidth <= this.mediaQuery) {
      return false;
    }

    if (this.router.url.includes(AppRoute.Browse)) {
      this._showSideBarExpanded$.next(false);
      return true;
    }
  }


  private _hideSideBar$ = new BehaviorSubject<boolean>(window.outerWidth <= 768);

  private _showSideBarExpanded$ = new BehaviorSubject<boolean>(this._sidebar);

  private _showBrowseSideBar$ = new BehaviorSubject<boolean>(this._browseBar);

  private _showSubBrowseBar$ = new Subject<[boolean, SubSubNavigationView]>();

  private _isBrowsePage$ = new BehaviorSubject<boolean>(this.router.url.includes(AppRoute.Browse));

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC PRIVATE DEPENDENT INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  public hideSideBar$: Observable<boolean> = this._hideSideBar$.asObservable();

  public showSideBarExpanded$: Observable<boolean> = this._showSideBarExpanded$.asObservable();

  public showBrowseSideBar$: Observable<boolean> = this._showBrowseSideBar$.asObservable();

  public showSubBrowseBar$: Observable<[boolean, SubSubNavigationView]> = this._showSubBrowseBar$.asObservable();

  public isBrowsePage$: Observable<boolean> = this._isBrowsePage$.asObservable();

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(private router: Router) {
    super();

    super.addSubscription(
      fromEvent(window, 'resize')
        .pipe(
          debounceTime(50)
        ).subscribe({
          next: () => {
            if (window.outerWidth <= this.mediaQuery && this._sidebar === false) {
              this._showSideBarExpanded$.next(false);
              this.toggleSubBrowseSideBar(null);
              this.toggleBrowseSideBar(false);
            } else if (window.outerWidth >= this.mediaQuery && this._sidebar === true && this._browseBar == false) {
              this._showSideBarExpanded$.next(true);
              this.toggleSubBrowseSideBar(null);
            } else if (window.outerWidth >= this.mediaQuery && this._sidebar === true && this._browseBar === true) {
              this._showSideBarExpanded$.next(false);
              this._showBrowseSideBar$.next(true);
            } else if (window.outerWidth >= this.mediaQuery && this._sidebar === false && this._browseBar === true) {
              this._showSideBarExpanded$.next(false);
              this._showBrowseSideBar$.next(true);
            } else if (window.outerWidth <= this.mediaQuery && this._browseBar === true) {
              this.toggleSubBrowseSideBar(null);
              this.toggleBrowseSideBar(false);
            }

            if ( window.outerWidth <= 768)
            {
              this._hideSideBar$.next(true);
            } else {
              this._hideSideBar$.next(false);
            }
          }
        })
    );

    super.addSubscription(
      this.router.events.pipe(
        filter((event: any) => event instanceof NavigationStart)
      ).subscribe({
        next: (event: NavigationStart) => {
          if (event.url.includes(AppRoute.Browse)) {
            this._isBrowsePage$.next(true);
            if (window.outerWidth >= this.mediaQuery) {
              this.toggleBrowseSideBar(true);
            } else {
              this.toggleBrowseSideBar(false);
            }
          } else if (event.url.includes(AppRoute.Checkout)) {
            this.toggleBrowseSideBar(false);
            this._isBrowsePage$.next(false);
            this._showSideBarExpanded$.next(false);
          } else {
            this.toggleBrowseSideBar(false);
            this._isBrowsePage$.next(false);
            this._showSideBarExpanded$.next(this._sidebar);
          }
        }
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * Opens or closes the sidebar and saves its state in to the local storage
   * @param isExpanded Flag containing information about the sideBar nedds to be opened or closed
   */
  toggleSideBar(expanded: boolean): void {
    this._sidebar = expanded;
    this._showSideBarExpanded$.next(expanded);
    this.toggleSubBrowseSideBar(null);
  }

  toggleBrowseSideBar(expanded: boolean): void {
    if (expanded === true) {
      this._showSideBarExpanded$.next(false);
    } else {
      this._showSideBarExpanded$.next(this._sidebar);
      this.toggleSubBrowseSideBar(null);
    }

    this._showBrowseSideBar$.next(expanded);
  }

  toggleSubBrowseSideBar(subSubNavigationView: SubSubNavigationView): void {
    if (!__.IsNullOrUndefinedOrEmpty(subSubNavigationView)) {
      this._showSubBrowseBar$.next([true, subSubNavigationView]);
      this._showSideBarExpanded$.next(false);
    } else {
      this._showSubBrowseBar$.next([false, null]);
    }

  }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';
import { BaseFormComponent } from '@app/shared/base/components/base-form.component';
import { __ } from '@app/shared/functions/object.functions';
import { Genre } from '@app/shared/models/classes/Genre';
import { Instrument } from '@app/shared/models/classes/Instrument';
import { Mood } from '@app/shared/models/classes/Mood';
import { SubGenre } from '@app/shared/models/classes/SubGenre';
import { Theme } from '@app/shared/models/classes/Theme';
import { Track, TrackVariation, TrackVariationTranslation, TrackVariationUpdateDTO } from '@app/shared/models/classes/Track';
import { LanguageCode } from '@app/shared/models/enums/LanguageCode.enum';
import { Vocal } from '@app/shared/models/enums/Vocals.enum';
import { MusicalKeyTypesHelper } from '@app/shared/models/helpers/MusicalKeyTypes.helper';
import { VocalTypesHelper } from '@app/shared/models/helpers/Vocals.helper';
import { GenreService } from '@app/shared/services/genre.service';
import { InstrumentService } from '@app/shared/services/instrument.service';
import { MoodService } from '@app/shared/services/mood.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { TracksService } from '@app/shared/services/tracks.service';
import { TrackVariationsService } from '@app/shared/services/trackvariations.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize } from 'rxjs';

@Component({
    selector: 'sound-tracks-form',
    templateUrl: './tracks-form.component.html',
    styleUrls: ['./tracks-form.component.scss'],
    standalone: false
})
export class TracksFormComponent extends BaseFormComponent<TrackVariation> implements OnInit {

  /// -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  moods: Mood[] = [];

  themes: Theme[] = [];

  genres: Genre[] = [];

  subGenres: SubGenre[] = [];

  instruments: Instrument[] = [];

  vocals: any[] = VocalTypesHelper.GetSelectorVocalTypes();

  energyLevels: any[] = [];

  keys: any[] = MusicalKeyTypesHelper.MusicalKeyTypes;

  inADay: Moment = moment().add(1, 'days');

  isLoadingSubmit: boolean = false;

  isCreatorRoute: boolean = false;

  track: Track;

  /// -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Output() cancelClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _trackVariationId: string;
  @Input()
  get trackVariationId(): string {
    return this._trackVariationId;
  }
  set trackVariationId(value: string) {
    if (!this.IsNullOrUndefined(value)) {
      this._trackVariationId = value;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private tracksService: TracksService,
    private genreService: GenreService,
    private moodService: MoodService,
    private themeService: ThemeService,
    private instrumentService: InstrumentService,
    private trackVariationsService: TrackVariationsService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    super();

    this.createForm();
  }

  /// -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {

    if (this.router.url.includes(AppRoute.Creator)) { this.isCreatorRoute = true; }

    this.isLoading = true;

    this.loadFormFieldData();

    super.addSubscription(this.tracksService
      .getById(this.itemId, '', true, true)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        catchError((error: any) => {
          throw error;
        })
      )
      .subscribe({
        next: (track: Track) => {
          this.track = track;
          this.item = track.variations.find(q => q.id === this.trackVariationId);
          this.patchFormValues();
        }
      }));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  loadFormFieldData(): void {

    // Genres
    super.addSubscription(
      this.genreService.getAll('orderby=displayName', 0, 10000, null, false
      ).subscribe({
        next: (genres: Genre[]) => {
          this.genres = genres
            .filter(q => !this.IsNullOrUndefinedOrEmpty(q.displayName));
          this.genres.map(q => (q as any).value = q.id);

          // we have tracks with subgenres assigned to them so this is necessary always
          const subGenres = this.genres.map(q => q.subGenres).filter(q => q.length !== 0);

          if (subGenres.length > 0) {
            this.subGenres = subGenres
              .reduce((prev, curr) => prev.concat(curr))
              .sort((a, b) => a.displayName > b.displayName ? 1 : a.displayName < b.displayName ? -1 : 0);

            this.subGenres.map(q => (q as any).value = q.id);
          }
        }
      })
    );

    // Moods
    super.addSubscription(
      this.moodService.getAll('orderby=displayName', 0, 10000, null, false).subscribe({
        next: (moods: Mood[]) => {
          this.moods = moods;
          this.moods.map(q => (q as any).value = q.id);
        }
      })
    );

    // Themes
    super.addSubscription(
      this.themeService.getAll('orderby=displayName', 0, 10000, null, false).subscribe({
        next: (themes: Theme[]) => {
          this.themes = themes;
          this.themes.map(q => (q as any).value= q.id);
        }
      })
    );

    // Instruments
    super.addSubscription(
      this.instrumentService.getAll('orderby=displayName', 0, 10000, null, false).subscribe({
        next: (instruments: Instrument[]) => {
          this.instruments = instruments;
          this.instruments.map(q => (q as any).value = q.id);
        }
      })
    );
  }

  patchFormValues(): void {
    const englishTranslations = this.item.trackVariations_i18n.find(q => q.languageCode === 'en-US');
    const germanTranslations = this.item.trackVariations_i18n.find(q => q.languageCode === 'de-DE');

    this.form.setValue({
      displayName: this.item?.displayName || '',
      descriptionDE: germanTranslations?.description || '',
      descriptionEN: englishTranslations?.description || '',
      moods: this.item?.moods.map(q => (q as any).value = q.id) || [],
      themes: this.item?.themes.map(q => (q as any).value = q.id) || [],
      genres: this.item?.genres.map(q => (q as any).value = q.id) || [],
      subGenres: this.item?.subGenres.map(q => (q as any).value = q.id) || [],
      keyWordsDE: germanTranslations?.keyWords.toString() || [],
      keyWordsEN: englishTranslations?.keyWords.toString() || [],
      instruments: this.item?.instruments.map(q => (q as any).value = q.id) || [],
      soundsLike: this.item?.soundslike || '',
      vocals: this.IsNullOrUndefinedOrEmpty(this.item?.vocals) ? [] : this.item?.vocals.filter(q => q !== Vocal.Instrumental),
      bpm: this.IsNullOrUndefined(this.item?.bpm) ? null : this.item?.bpm,
      energyLevel: this.IsNullOrUndefined(this.item?.energyLevel) ? null : this.item?.energyLevel,
      musicalKeyType: this.IsNullOrUndefined(this.item?.musicalKeyType) ? '' : this.item?.musicalKeyType,
      isBeatFree: this.item?.isBeatfree || false,
      isInstrumental: this.IsNullOrUndefinedOrEmpty(this.item?.vocals) ? false : this.item?.vocals.some(q => q === Vocal.Instrumental),
      isFeatured: this.item?.isFeatured || false,
      length: this.IsNullOrUndefined(this.item?.length) ? null : this.item?.length,
      isPro: this.track.isPro,
    });
  }

  update(): void {

  }

  create(): void {

  }


  submit(): void {
    if (this.form.invalid === false) {
      const value = this.form.getRawValue();

      // Translations
      value['translations'] = [];
      for (const language of [LanguageCode.enUS, LanguageCode.deDE]) {

        const keyWords: string[] = [];

        if (!__.IsNullOrUndefinedOrEmpty(value[`keyWords${language.split('-')[0].toUpperCase()}`])) {
          keyWords.push(...value[`keyWords${language.split('-')[0].toUpperCase()}`].split(','));
        }

        const translation: TrackVariationTranslation = {
          languageCode: language,
          keyWords,
          description: value[`description${language.split('-')[0].toUpperCase()}`]
        };
        value['translations'].push(translation);
      }

      this.isLoadingSubmit = true;

      super.addSubscription(
        this.trackVariationsService
          .update(this.trackVariationId,
            Object.assign(
              {
                displayName: value.displayName,
                soundslike: value.soundsLike,
                bpm: Number(value.bpm),
                energyLevel: value.energyLevel,
                vocals: value.isInstrumental === true ? [...value.vocals, Vocal.Instrumental] : value.vocals,
                musicalKeyType: value.musicalKeyType,
                moodIds: value.moods,
                themeIds: value.themes,
                genreIds: value.genres,
                subgenreIds: value.subGenres,
                instrumentIds: value.instruments,
                translations: value.translations,
                isFeatured: value.isFeatured,
                isBeatfree: value.isBeatFree,
                status: this.item.status,
                isOriginal: this.item.isOriginal,
                isPro: value.isPro,
              }) as TrackVariationUpdateDTO
          )
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe({
            next: (trackVariation: TrackVariation) => {
              this.item = trackVariation;
              this.toastr.success(this.translateService.instant('Tracks.The track variation has been successfully updated'));
              this.saved.emit(true)
            },
            complete: () => {
              this.isLoadingSubmit = false;
            },
            error: (error: any) => {
              this.isLoadingSubmit = false;
              this.cdr.detectChanges();

              switch (error.error.code.internalCode) {
                default:
                  this.toastr.error(this.translateService.instant('Tracks.The track variation could not be updated'));
                  break;
              }
            }
          })
      );
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private createForm() {
    this.form = this.formBuilder.group({
      displayName: ['', Validators.required],
      descriptionDE: [''],
      descriptionEN: [''],
      moods: [[], Validators.required],
      themes: [[]],
      genres: [[], Validators.required],
      subGenres: [[]],
      keyWordsDE: [[]],
      keyWordsEN: [[]],
      instruments: [[]],
      soundsLike: [''],
      vocals: [[]],
      bpm: [''],
      length: [''],
      energyLevel: [''],
      musicalKeyType: ['', Validators.required],
      isBeatFree: [false],
      isInstrumental: [false],
      isFeatured: [false],
      isPro: [false],
    });
  }

}

import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/base/services';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class WaveformLoadingService extends BaseService {

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _isLoading: boolean = false;

  private _isLoading$: Subject<boolean> = new Subject<boolean>();

  // tslint:disable-next-line:member-ordering
  isLoading$: Observable<boolean> = this._isLoading$.asObservable();

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor() {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  setLoading(isLoading: boolean): void {
    this._isLoading = isLoading;
    this._isLoading$.next(isLoading);
  }

  get loading(): boolean {
    return this._isLoading;
  }
}
import { Injectable } from '@angular/core';
import { I18nService } from '@app/core/i18n.service';
import { map, Observable } from 'rxjs';
import { PeakData } from '../models/classes/PeakData';
import { TrackVariation, TrackVariationUpdateDTO } from '../models/classes/Track';
import { TrackVariationStatus } from '../models/enums/TrackVariationStatus.enum';
import { EntityService } from './entity.service';
import { Response } from './local/Response';
import { HttpService } from '@app/core/http/http.service';

@Injectable()
export class TrackVariationsService extends EntityService<TrackVariation, TrackVariation, TrackVariationUpdateDTO> {

    /// -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(protected httpService: HttpService, protected i18nService: I18nService) {
        super('track-variations', 'trackVariation', httpService, i18nService);
    }

    getPeakDataByURL(url: string): Observable<PeakData> {
        return this.httpService.disableApiPrefix().disableAccessToken().get<PeakData>(url)
            .pipe(
                map((result: PeakData) => {
                    return result;
                })
            );
    }

    /**
     * Updates track variations status.
     * 
     * @param trackVariationIds the track variation ids
     * @param status the status to change either publish or unpublish (default: true)
     * @returns Observable<Track[]>
     */
    updateTrackVariationStatusByIds(ids: string[], status: TrackVariationStatus, isSingleUpdate: boolean = false): Observable<string> {
        return this.httpService.patch<Response<string>>(`track-variations/status`, {
            trackVariationIds: ids,
            status,
            isSingleUpdate
        }).pipe(
            map((result: Response<string>) => {
                return result.data;
            })
        );
    }
}
<!-- FORM -->
<div class="flex gap-2 flex-grow-0 items-start">

    <!-- DESCRIPTION -->
    <div class="grow comment-field">
        <jad-form-fields-textarea class="grow" [control]="form.get('comment')"
            [placeholder]="'MyProjects.track.comment' | translate"
            [displayName]="'MyProjects.track.comment' | translate" [required]="false"></jad-form-fields-textarea>
    </div>
    <!-- SUBMIT -->
    <sound-buttons-submit class="my-projects__actions__submit mt-5" [icon]="'floppy-disk'" [iconClass]="'small'"
        [isLoading]="isLoading" [isSuccessful]="isSuccessful" [isDisabled]="!form.valid || isLoading === true"
        (onClick)="submit()">
    </sound-buttons-submit>
</div>
<div class="text-sm -mt-7 mb-8" style="color: #969699;">
    {{'MyProjects.track.last edit' | translate}} {{item?.modified | date:'MMM dd, yyyy \'at\' HH:mm' }} {{item?.modifiedByName}}
</div>
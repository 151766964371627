

export enum TrackSort {
    None = 'None',
    Newest = 'Newest',
    Oldest = 'Oldest',
    BestsellerLastMonth = 'BestsellerLastMonth',
    BestsellerLastYear = 'BestsellerLastYear',
    BestsellerAllTime = 'BestsellerAllTime'
}
